import React from "react";
import Navbar from "../../Common/Navbar";
import Footer from "../Footer";
import { Box, Typography, Divider , Button} from "@mui/material";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdOutlineChevronLeft } from "react-icons/md";
import { Link } from 'react-router-dom';

const Privacy = () => {
  return (
    <>
      <Navbar />
      <Box
        sx={{
          pt: 15,
          px: 4,
          overflow: "scroll",
          width: {lg:"40%", md:'50%',  sm:'70%', xs:'100%'},
          margin: "0 auto",
          boxSizing: "border-box",
        }}
      >
        <Typography sx={{ fontSize: "25px", fontWeight: 500 }}>
          Privacy Policy
        </Typography>
        <Typography sx={{ mt: 1, color: "#6f7f94" }}>
          This is the privacy policy for Resity.
        </Typography>
        <Divider sx={{ my: 2 }} />

        <Box sx={{ mt: 3 }}>
          <Typography sx={{ fontSize: "14px" }}>
            Effective Date: November 15, 2023
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
            Introduction
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Welcome to the Reisty Platform ("Platform," "we," "our," or "us").
            This Privacy Policy outlines how we collect, use, and safeguard your
            personal information when you access or use our Platform.
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
            Definitions
          </Typography>
          <Box sx={{ mt: 2 }}>
            <ul>
              <li>
                <Typography sx={{ mt: 1 }}>
                  Personal Data: Refers to any information that can be used to
                  identify an individual.
                </Typography>
              </li>
              <li>
                <Typography sx={{ mt: 1 }}>
                  Usage Data: Collects information about how our Platform is
                  accessed and used.
                </Typography>
              </li>
              <li>
                <Typography sx={{ mt: 1 }}>
                  Location Data: Refers to data that may be obtained from the
                  user's device based on their location settings.
                </Typography>
              </li>
              <li>
                <Typography sx={{ mt: 1 }}>
                  KYC Information: Refers to Know Your Customer data required
                  for identity verification purposes.
                </Typography>
              </li>
              <li>
                <Typography sx={{ mt: 1 }}>
                  Tracking Cookies Data: Refers to the data collected through
                  cookies and similar tracking technologies.
                </Typography>
              </li>
            </ul>
          </Box>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
            Use of Data
          </Typography>
          <Typography sx={{ mt: 2 }}>
            We use the collected data for various purposes, including providing
            and maintaining the Platform, improving our services, and ensuring
            compliance with legal requirements.
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
            Retention of Data
          </Typography>
          <Typography sx={{ mt: 2 }}>
            We retain your data only for as long as necessary to fulfill the
            purposes outlined in this Privacy Policy or as required by law.
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
            Transfer of Data
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Your information, including Personal Data, may be transferred to and
            stored on servers outside your country or region. By using our
            Platform, you consent to such data transfers.
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
            Security of Data
          </Typography>
          <Typography sx={{ mt: 2 }}>
            We implement appropriate security measures to protect your data from
            unauthorized access, alteration, or disclosure. Your Data Protection
            Rights Under General Data Protection Regulation (GDPR)
            <br />
            <br />
            As a data subject, you have certain rights under GDPR, including the
            right to access, rectify, and erase your data
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
Service Providers
          </Typography>
          <Typography sx={{ mt: 2 }}>
      We may employ third-party companies and individuals to assist with our services, and they are obligated to protect your data.
        
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
Analytics
          </Typography>
          <Typography sx={{ mt: 2 }}>
We use analytics tools to gather information about Platform usage to improve services and user experience.
        
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
CI/CD Tools
          </Typography>
          <Typography sx={{ mt: 2 }}>
Continuous Integration and Continuous Deployment tools are used to streamline our software development and ensure efficiency.
        
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
Advertising and Behavioral Re-marketing
          </Typography>
          <Typography sx={{ mt: 2 }}>
We work with vendors such as Meta, Google, Twitter, and LinkedIn to display ads and perform re-marketing actions.<br/><br/>
These vendors may use cookies and similar tracking technologies to serve relevant ads based on your past visits to our Platform.
        
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
Payments
          </Typography>
          <Typography sx={{ mt: 2 }}>
Payment transactions are processed via the payment platform Paystack. Please refer to Paystack's privacy policy at <a href="https://paystack.com/privacy/merchant#:~:text=Paystac" style={{textDecoration:'underline'}}><b>https://paystack.com/privacy/merchant#:~:text=Paystack</b></a> does not sell%2C trade,described in this Privacy Policy."
        
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
Links to Other Sites
          </Typography>
          <Typography sx={{ mt: 2 }}>
Our Platform may contain links to external websites. We are not responsible for the content or privacy practices of these sites.
        
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
Children's Privacy
          </Typography>
          <Typography sx={{ mt: 2 }}>
Our Platform is not intended for children under the age of 16. We do not knowingly collect Personal Data from minors
        
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
Changes to this Privacy Policy
          </Typography>
          <Typography sx={{ mt: 2 }}>
We reserve the right to update our Privacy Policy, and the updated version will be posted on our Platform.
        
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              borderBottom: "1px solid #d7d7d7",
              pb: 1,
            }}
          >
Contact Us
          </Typography>
          <Typography sx={{ mt: 2 }}>
If you have any questions or concerns about this Privacy Policy, please contact us at support@reisty.com. Thank you for using Reisty.
        
          </Typography>
        </Box>
          <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between', my:4}}>
    <Link to="/help">

    <Button startIcon={<MdOutlineChevronLeft />}>Help and Feedback</Button>
    </Link>
        <Link to="/terms">

    <Button endIcon={<MdKeyboardArrowRight />}>Term of Service</Button>
        </Link>
  </Box>
      </Box>
      <Footer />
    </>
  );
};

export default Privacy;
