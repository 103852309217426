import React from "react";
import Navbar from "../../Common/Navbar";
import Footer from "../Footer";
import { Box, Typography, Divider, Button } from "@mui/material";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import SEO from "../../../../utils/SEO";

const About = () => {
  return (
    <>
    <SEO
            title="About Reisty - Connecting Diners with Premier Restaurants in Lagos"
        description="Learn more about Reisty, your premier guide to Lagos' restaurant scene. Our mission is to simplify reservations, helping you discover new dining spots and make informed decisions with ease."
        name="Reisty"
        type="article"
                canonicalUrl="https:/reisty.com/about"
    />
      <Navbar />
      <Box
        sx={{
          pt: 15,
          px: 4,
          height: "100vh",
          overflow: "scroll",
          width: {lg:"40%", md:'50%',  sm:'70%', xs:'100%'},
          margin: "0 auto",
          boxSizing: "border-box",
        }}
      >
        <Typography sx={{ fontSize: "25px", fontWeight: 700 }}>
          About
        </Typography>
        <Typography sx={{ mt: 1, color: "#6f7f94" }}>
          About Reisty and how we enhance your culinary experience.
        </Typography>
        <Divider sx={{ my: 2 }} />

        <Box sx={{ mt: 3 }}>
          <Typography sx={{ fontSize: "14px" }}>
            Reisty is an online restaurant reservation service that connects you
            with various restaurants, from trendy cafes to fine dining
            establishments in Lagos. Our mission is to simplify the restaurant
            reservation process and empower diners to quickly discover, view
            menus, and read reviews before booking a restaurant table.
          </Typography>

          <Box sx={{ mt: 3 }}>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 700,
                borderBottom: "1px solid #d7d7d7",
                pb: 1,
              }}
            >
              Mission
            </Typography>
            <Typography sx={{ mt: 3 }}>
              At Reisty, we aim to revolutionize the dining experience by
              providing Reistars with a seamless and efficient platform for
              restaurant reservations and discovery. We strive to be the best
              reservation app for restaurants, offering cutting-edge reservation
              management tools, curated restaurant discovery features, and
              personalized recommendations to enhance your dining journey. Our
              commitment to excellence drives us to continuously innovate and
              deliver exceptional value to our Reistars, making us the go-to
              dining companion in Lagos.
            </Typography>
          </Box>
          <Box sx={{ mt: 3 }}>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 700,
                borderBottom: "1px solid #d7d7d7",
                pb: 1,
              }}
            >
              Vision
            </Typography>
            <Typography sx={{ mt: 3 }}>
              Our vision is to be the leading online reservation system for
              restaurants in Lagos, setting the benchmark for dining technology
              and hospitality. We aim to expand our reach, connecting Reistars
              with the best restaurants near them and providing them with a
              trusted platform to explore, book, and enjoy dining experiences.
              By leveraging advanced technology, data-driven insights, and a
              passion for culinary excellence, we aspire to transform how people
              dine out, making every meal a delightful celebration for our
              Reistars.
              <br />
              <br />
              Being a top platform for restaurant reservations, we are committed
              to putting foodies in touch with the ideal restaurant at their
              fingertips for every occasion, and we're dedicated to providing
              every diner we serve with culinary enjoyment, convenience, and
              tailored recommendations—we don't just take reservations. We also
              serve as: <br />
              <br />
              <b>Your Culinary Companion:</b> Reisty is more than just a dining
              platform – we're your ultimate culinary companion, dedicated to
              guiding you through the vibrant and diverse culinary scene of
              Lagos. With us, every meal becomes a delightful celebration of
              flavor and culture.
              <br />
              <br />
              <b>Discover Lagos' Finest Restaurants:</b> We meticulously curate
              a list of the top restaurants in Lagos, ensuring you have access
              to the city's finest dining establishments. Whether you're
              planning a romantic dinner, a birthday celebration, or a
              professional business meeting, Reisty has the perfect spot for
              every occasion. Make your Reisty booking online with ease and
              confidence.
              <br />
              <br />
              <b>Efficient Waitlist Optimization:</b> With our cutting-edge
              waitlist optimization tool, say goodbye to long wait times and
              hello to efficient seating management. Receive real-time updates,
              estimate wait times, and enhance your dining experience. Sign up
              for free and effortlessly streamline your waitlist management with
              Reisty.
              <br />
              <br />
              <b> Luxury Dining Made Accessible:</b> With Reisty, you can enjoy
              top-notch dining experiences in Lagos without breaking the bank.
              We highlight restaurants with great menus and atmospheres, making
              it easy to find the perfect spot, like Restaurant C and Restaurant
              D.
              <br />
              <br />
              <b>Smooth Reservation Process:</b> Booking your table has always
              been challenging with Reisty's comprehensive reservation guides.
              Whether you're a seasoned diner or a newcomer, we provide
              step-by-step instructions and insider tips to ensure a hassle-free
              booking experience every time.
              <br />
              <br />
              <b>Seamless Mobile Experience:</b> Enjoy seamless restaurant
              reservations, personalized dining experiences, and more at your
              fingertips. Your culinary journey awaits, just a click away.
              Elevate your dining adventures with the Reisty app, available for
              download on the App Store and Google Play Store.
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
            my: 4,
          }}
        >
          <Link to="/contact">
            <Button endIcon={<MdKeyboardArrowRight />}>Contact</Button>
          </Link>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default About;
