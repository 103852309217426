import React, { useEffect, useState } from "react";
import { Link, redirect, useNavigate, useParams } from "react-router-dom";
import { getOpeningHours, getRestaurantDetails } from "../../api";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import {
  Box,
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  IconButton,
  Popover,
  ListItemButton,
  LinearProgress,
  Button,
} from "@mui/material";
import logo from "../../Assets/logo/logo.svg";
import BounceLoader from "react-spinners/BounceLoader";
import Calender from "../../Main/Components/Common/Calendar";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { RiArrowDropDownFill } from "react-icons/ri";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import CarouselWidget from "../../Main/Components/Common/CarouselWidget";
import OpenTag from "../../Main/Components/Common/OpenTag";
import { BsCalendar2CheckFill } from "react-icons/bs";
import { BsFillPeopleFill } from "react-icons/bs";
import { GoClockFill } from "react-icons/go";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";

const override = {
  display: "block",
  margin: "auto",
  borderColor: "#BC172F",
};

const ReservationWidget = () => {
  const { name } = useParams();
  const [loading, setLoading] = useState(false);
  const [restaurant, setRestaurant] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openingHours, setOpeningHours] = useState(null);
  const [selectedTime, setSelectedTime] = useState("");
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
      const { enqueueSnackbar } = useSnackbar() ;

            const handleAlert = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };

  const dateObject = new Date();
  const toDate = dateObject.toISOString().split("T")[0];
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const pid = open ? "simple-popover" : undefined;
  const [date, setDate] = useState(toDate);

  useEffect(()=>{
    setDate(toDate)
  }, [])

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGetRestaurantDetails = async () => {
    setLoading(true);
    await getRestaurantDetails(name)
      .then((res) => {
        setLoading(false);
        // console.log(res);
        if (res?.data?.status) {
          setRestaurant(res?.data?.result[0]);
        }
      })
      .catch((err) => {
        setLoading(false);
        // console.log(err);
      });
  };

  useEffect(() => {
    handleGetRestaurantDetails();
  }, []);

  const [guest, setGuest] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const handleGetTime = async () => {
    setIsLoading(true);
    setOpeningHours(null)
    await getOpeningHours(restaurant?.RestaurantId, date, guest)
      .then((res) => {
        setIsLoading(false);
        if(res?.data?.status){

          setOpeningHours(res?.data?.result[0]?.GroupedTime);
        }else{
   handleAlert("error", `${res?.data?.error_message}`)
     setOpeningHours(null)
        }
      })
      .catch((err) => {
         handleAlert("error", `${err?.message}`)
        setIsLoading(false);
          setOpeningHours(null)
      });
  };

  useEffect(() => {
    if(restaurant?.RestaurantId){

      handleGetTime();
    }
  }, [name, date, restaurant, guest]);

  const navigate = useNavigate()

  return (
    <>
      <Box
        sx={{
          backgroundColor: "rgba(188, 23, 47, 0.05)",
        minHeight:'100vh',
          backgroundSize: "contain",

        }}
      >
        <Box
          className="hide_scrollbar"
          sx={{
            margin: "0 auto",
            width: { xl: "30%", lg: "40%", md: "50%", sm: "65%", xs: "100%" },
            bgcolor: "#fff",
        minHeight: "100vh",
     
    pb:4
          }}
        >
          {loading ? (
            <>
              <Box sx={{ height: "100vh", display: "grid", placeItems:'center' , }}>
                <BounceLoader
                  color={"#BC172F"}
                  loading={true}
                  cssOverride={override}
                  size={100}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </Box>
            </>
          ) : (
            <>
               <Box
            sx={{
              pt: 5,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src={logo} width={90} />
            <Box
              sx={{
                mt: 2,
                display: "flex",
                alignItems: "center",
                columnGap: 2,
                px: 3,
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                  overflow: "hidden",
                }}
              >
                Make a Reservation at
                <span style={{ textTransform: "uppercase", fontWeight: 700 }}>
                  {" "}
                  {name}
                </span>
              </Typography>
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                  overflow: "hidden",
                  fontSize:'12px',
                  mt:0.5
                }}
              >
         
                 Opens from <b> {restaurant?.
OpenFrom?.substring(0, 8) || "--"
}</b>
          
              </Typography>
              {/* <OpenTag hoursString={restaurant?.OpenFrom} /> */}
            </Box>
            <Box sx={{ mt: 2, width: "100%" }}>
              <CarouselWidget
                images={restaurant?.Photos}
                description={restaurant?.Description}
                title={restaurant?.RestaurantName}
              />
            </Box>
          </Box>
          <Box
            className="hide_scrollbar"
            sx={{ margin: "0 auto", width: "90%", mt: 3, overflow: "scroll" }}
          >
            {activeStep === 0 && (
              <>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Box>
                      <InputLabel>Number of Guest(s)</InputLabel>
                      <TextField
                        value={`${guest}  Guest`}
                        margin="dense"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => {
                                  if (guest > 1) {
                                    setGuest(guest - 1);
                                  }
                                }}
                                sx={{
                                  border: "1px solid ",
                                  borderRadius: "5px",
                                }}
                              >
                                <FaMinus style={{ fontSize: "12px" }} />
                              </IconButton>
                              <IconButton
                                onClick={() => setGuest(guest + 1)}
                                sx={{
                                  border: "1px solid ",
                                  borderRadius: "5px",
                                  ml: 1,
                                }}
                              >
                                <FaPlus style={{ fontSize: "12px" }} />
                              </IconButton>
                            </InputAdornment>
                          ),
                          style: {
                            fontSize: "12px",
                            borderRadius: "8px",
                          },
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Box>
                      <InputLabel>Date</InputLabel>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                          border: "1px solid #d7d7d7",
                          px: 2,
                          mt: 1,
                          pb: 0.5,
                          boxSizing: "border-box",
                          borderRadius: "8px",
                          height: "50px",
                        }}
                        aria-describedby={pid}
                        onClick={handleClick}
                      >
                        <Box>
                          <Typography
                            sx={{ fontWeight: 500, fontSize: "12px" }}
                          >
                            {formatDate(date)}
                          </Typography>
                        </Box>
                        <RiArrowDropDownFill style={{ fontSize: "20px" }} />
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box>
                      <InputLabel>Time</InputLabel>
                      <Grid container spacing={2} sx={{ mt: 0 }}>
                        {isLoading && (
                          <Box align="center" sx={{ width: "100%" }}>
                            <LinearProgress sx={{ width: "50%" }} />
                          </Box>
                        )}

                        {!openingHours ||
                        openingHours.length === 0 ||
                        (openingHours[0].length === 0 &&
                          openingHours[1]?.length === 0) ? (
                          <>
                            <Box sx={{ p: 1, width: "100%", mt: 1 }}>
                              <Typography
                                sx={{
                                  textAlign: "center",
                                  fontWeight: 600,
                                  color: "#BC172F",
                                }}
                              >
                                No Available Time for the selected date
                              </Typography>
                              <Typography
                                sx={{
                                  textAlign: "center",
                                  fontWeight: 300,
                                  fontSize: "12px",
                                }}
                              >
                                You can try selecting another date
                              </Typography>
                            </Box>
                          </>
                        ) : (
                          <>
                            {openingHours &&
                              openingHours[0]?.map((time, index) => {
                                return (
                                  <Grid
                                    item
                                    lg={3}
                                    md={3}
                                    sm={4}
                                    xs={4}
                                    key={index}
                                  >
                                    <ListItemButton
                                        disabled={!time?.IsAvailable}
                                      onClick={() => {
                                        setSelectedTime(time?.Time);
                                      }}
                                      selected={selectedTime === time?.Time}
                                      sx={{
                                        border: "1px solid #d7d7d7",
                                        borderRadius: "8px",
                                        "&.Mui-selected": {
                                          border: "1px solid #BC172F",
                                          color: "#BC172F",
                                        },
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: "12px",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        {" "}
                                        {time?.Time}
                                      </Typography>
                                    </ListItemButton>
                                  </Grid>
                                );
                              })}
                            {openingHours &&
                              openingHours[1]?.map((time, index) => {
                                return (
                                  <Grid
                                    item
                                    lg={3}
                                    md={3}
                                    sm={4}
                                    xs={4}
                                    key={index}
                                  >
                                    <ListItemButton
                                        disabled={!time?.IsAvailable}
                                      onClick={() => {
                                        setSelectedTime(time?.Time);
                                      }}
                                      selected={selectedTime === time?.Time}
                                      sx={{
                                        border: "1px solid #d7d7d7",
                                        borderRadius: "8px",
                                        "&.Mui-selected": {
                                          border: "1px solid #BC172F",
                                          color: "#BC172F",
                                        },
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: "12px",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        {" "}
                                        {time?.Time}
                                      </Typography>
                                    </ListItemButton>
                                  </Grid>
                                );
                              })}
                          </>
                        )}
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>

                <Box sx={{ mt: 4, mb: 5 }}>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{ borderRadius: "12px", py: 2 }}
                    disabled={!guest || !date || !selectedTime}
                    onClick={() => {
if(isAuthenticated){
navigate(`/reservation/user?restaurantName=${name}&guest=${guest}&time=${selectedTime}&date=${date}&deposit=${restaurant?.DepositPerPerson}`)
}else{

  setActiveStep(1)}
}

                  }
                  >
                    Proceed
                  </Button>
                </Box>
              </>
            )}
            {activeStep === 1 && (
              <>
                <Box>
                  <Button
                    onClick={() => setActiveStep(0)}
                    startIcon={<IoArrowBackCircleOutline />}
                  >
                    Back
                  </Button>
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    border: "1px solid #d7d7d7",
                    borderRadius: "8px",
                  }}
                >
                  <Box sx={{ p: 2, borderBottom: "1px solid #d7d7d7" }}>
                    <Typography color="primary" sx={{ fontWeight: 700 }}>
                      Reservation Details
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      p: 2,
                      display: "flex",
                      columnGap: 2,
                      borderBottom: "1px solid #d7d7d7",
                    }}
                  >
                    <Box
                      sx={{
                        background: `url('${restaurant?.Photos[0]?.Link}')`,
                        height: "100px",
                        width: "100px",
                        borderRadius: "8px",
                        backgroundSize: "cover",
                      }}
                    />
                    <Box>
                      <Typography sx={{ fontWeight: 600 }}>
                        {restaurant?.RestaurantName}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 1,
                          mt: 1,
                        }}
                      >
                        <BsCalendar2CheckFill style={{ fontSize: "14px" }} />
                        <Typography sx={{ fontSize: "12px" }}>
                          {formatDate(date)}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 1,
                          mt: 0.4,
                        }}
                      >
                        <BsFillPeopleFill style={{ fontSize: "14px" }} />
                        <Typography
                          sx={{ fontSize: "12px" }}
                        >{`${guest} Guest`}</Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 1,
                          mt: 0.4,
                        }}
                      >
                        <GoClockFill style={{ fontSize: "14px" }} />
                        <Typography sx={{ fontSize: "12px" }}>
                          {selectedTime}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ p: 2 }}>
                    <Link to={"/login"} state={{redirectTo:`/reservation/user?restaurantName=${name}&guest=${guest}&time=${selectedTime}&date=${date}&deposit=${restaurant?.DepositPerPerson}`}}>
                      <Button
                        variant="contained"
                        fullWidth
                        sx={{ borderRadius: "12px", py: 2 }}
                      >
                        Login with Reisty
                      </Button>
                    </Link>
                    <Typography sx={{ textAlign: "center", my: 2 }}>
                      or
                    </Typography>
                    <Link
                      to={`/${name}/reservation/guest-booking?PartySize=${guest}&time=${selectedTime}&ReservationDay=${date}&deposit=${restaurant?.DepositPerPerson}`}
                    >
                      <Button
                        variant="outlined"
                        fullWidth
                        sx={{ borderRadius: "12px", py: 2 }}
                      >
                        Continue as Guest
                      </Button>
                    </Link>
                  </Box>
                </Box>
              </>
            )}
          </Box>
            </>
          )}
       
        </Box>
      </Box>

      <Popover
        id={pid}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box>
          <Calender
            future={false}
            past={true}
            onChildValue={(value) => {
              setDate(value);
              setAnchorEl(null);
              console.log(date);
            }}
          />
        </Box>
      </Popover>
    </>
  );
};

export default ReservationWidget;

function formatDate(inputDate) {
  // Create a Date object
  const date = new Date(inputDate);

  // Define an array of day and month names
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const monthsOfYear = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Extract the day of the week, month, and day of the month
  const dayOfWeek = daysOfWeek[date.getDay()];
  const month = monthsOfYear[date.getMonth()];
  const dayOfMonth = date.getDate();

  // Format the date string
  const formattedDate = `${dayOfWeek}, ${month} ${dayOfMonth}`;

  return formattedDate;
}
