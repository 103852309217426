import React, { useState, useEffect } from "react";
import Navbar from "../Components/Common/Navbar";
import Footer from "../Components/Footer/Footer";
import {
  Box,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
  Button,
  Avatar,
  Divider,
  Pagination,
  LinearProgress
} from "@mui/material";
import Client from "../../utils/Client";
import { urlFor } from "../../utils/SanityImageClient";
import { Link } from "react-router-dom";
import ocean from "../../Assets/images/ocean.jpeg";
import FooterHero from "../Components/Footer/FooterHero";
import BlogCard from "../Components/Common/BlogCard";
import SEO from "../../utils/SEO";

const Blogs = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [mainBlog, setMainBlog] = useState(null);

  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    Client.fetch(
      `*[_type == "event"] | order(date desc) {
    name,
    slug,
    blogType,
    date,
    description,
    image,
    details,
    readDuration
  }`
    )
      .then((data) => {
        setBlogs(data);
        setMainBlog(data[0]);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setMainBlog(blogs[0]);
  }, [blogs]);

  const [page, setPage] = useState(1);
  const itemsPerPage = 8; // Number of items per page

  // Calculate the number of pages
  const count = Math.ceil(blogs.length / itemsPerPage);

  // Slice the data based on the current page
  const paginatedBlogs = blogs.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  // Handle page change
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
            <SEO
            title="Reisty Blog - Tips, Guides, and Reviews on Lagos' Dining Scene"
        description="Dive into the Reisty blog for expert tips, comprehensive guides, and the latest reviews on the best restaurants in Lagos. Perfect your dining plans with our insightful articles."
        name="Reisty"
        type="article"
                canonicalUrl="https:/reisty.com/contact"
    />
      <Navbar />
      {
        isLoading ? (
          <>
<Box sx={{display:'grid', placeItems:'center', height:'70vh'}}>
  <LinearProgress sx={{width:'200px'}} />
</Box>
          </>
        ):(
          <>
    <Box sx={{ pt: 10, width: "95%", margin: "0 auto" }}>
        <Box
          sx={{
            bgcolor: "#333",
            background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('${
              mainBlog && urlFor(mainBlog?.image?.asset?._ref).url()
            }')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: isMobile ? "280px" : "400px",
            borderRadius: "10px",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Box
            sx={{
              width: { xl: "75%", lg: "85%", sm: "90%", xs: "90%" },
              margin: "0 auto",
            }}
          >
            <Typography
              sx={{
                color: "#D54118",
                bgcolor: "#D541181a",
                width: "fit-content",
                px: 3,
                py: 0.2,
                fontSize: "12px",
              }}
            >
              {mainBlog?.blogType || "--"}
            </Typography>
            <Typography
              sx={{
                mt: 2,
                fontFamily: "optima",
                fontSize: isMobile ? "18px" : "25px",
                lineHeight: isMobile ? "18px" : "28px",
                fontWeight: 700,
                color: "#fff",
                width: "70%",
              }}
            >
              {mainBlog?.name || "--"}
            </Typography>
            <Typography
              className="two-line-ellipsis"
              sx={{
                color: "#fff",
                fontWeight: 300,
                mt: 1,
                width: "85%",
                fontSize: isMobile ? "12px" : "14px",
              }}
            >
              {mainBlog?.description || "--"}
            </Typography>
            <Link to={`/blogs/${mainBlog?.slug?.current}`}>
              <Button
                variant="contained"
                sx={{ width: "100px", mt: isMobile ? 2 : 3 }}
              >
                Read More
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
      <Box
        className="hide_scrollbar"
        sx={{
          width: { xl: "75%", lg: "85%", sm: "90%", xs: "90%" },
          margin: "0 auto",
          bgcolor: "#fff",
          mt: -4,
          p: 3,
          boxSizing: "border-box",
          boxShadow:
            " rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
          overflow: "scroll",
        }}
      >
        {isMobile ? (
          <>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: 3 }}>
              {blogs?.slice(1, 4)?.map((blog, index) => (
                <Box key={index}>
                  <Box
                    sx={{ display: "flex", alignItems: "center", columnGap: 3 }}
                  >
                    <Box
                      sx={{
                        height: "90px",
                        width: "100px",
                        bgcolor: "#333",
                        borderRadius: "8px",
                        background: ` url('${
                          blog && urlFor(blog?.image?.asset?._ref).url()
                        }')`,
                        backgroundSize: "cover",
                      }}
                    />
                    <Box sx={{ width: "150px" }}>
                      <Typography
                        sx={{
                          bgcolor: "#EBF0FD",
                          color: "#1A0BD6",
                          width: "fit-content",
                          p: 0.4,
                          borderRadius: "50px",
                          px: 1,
                          fontSize: "10px",
                        }}
                      >
                        Trending
                      </Typography>
                      <Typography
                        className="one-line-ellipsis"
                        sx={{ mt: 1, color: "#707070", fontWeight: 700 }}
                      >
                        {blog?.name || "--"}
                      </Typography>
                      <Typography
                        className="two-line-ellipsis"
                        sx={{
                          mt: 1,
                          color: "#707070",
                          fontSize: "12px",
                          fontWeight: 300,
                          lineHeight: "12px",
                        }}
                      >
                        {blog?.description || "--"}
                      </Typography>
                      <Link to={`/blogs/${blog?.slug?.current}`}>
                        <Typography
                          sx={{ fontSize: "10px", color: "primary.main" }}
                        >
                          Read More
                        </Typography>
                      </Link>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </>
        ) : (
          <Grid container spacing={4}>
            {blogs?.slice(1, 4)?.map((blog, index) => (
              <Grid item md={4} key={index}>
                <Box
                  sx={{ display: "flex", alignItems: "center", columnGap: 3 }}
                >
                  <Box
                    sx={{
                      height: "90px",
                      width: "100px",
                      bgcolor: "#333",
                      borderRadius: "8px",
                      background: ` url('${
                        blog && urlFor(blog?.image?.asset?._ref).url()
                      }')`,
                      backgroundSize: "cover",
                    }}
                  />
                  <Box sx={{ width: "60%" }}>
                    <Typography
                      sx={{
                        bgcolor: "#EBF0FD",
                        color: "#1A0BD6",
                        width: "fit-content",
                        p: 0.4,
                        borderRadius: "50px",
                        px: 1,
                        fontSize: "10px",
                      }}
                    >
                      Trending
                    </Typography>
                    <Typography
                      className="one-line-ellipsis"
                      sx={{ mt: 1, color: "#707070", fontWeight: 700 }}
                    >
                      {blog?.name || "--"}
                    </Typography>
                    <Typography
                      className="two-line-ellipsis"
                      sx={{
                        mt: 1,
                        color: "#707070",
                        fontSize: "12px",
                        fontWeight: 300,
                        lineHeight: "12px",
                      }}
                    >
                      {blog?.description || "--"}
                    </Typography>
                    <Link to={`/blogs/${blog?.slug?.current}`}>
                      <Typography
                        sx={{ fontSize: "10px", color: "primary.main" }}
                      >
                        Read More
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
      <Box
        sx={{
          width: { xl: "75%", lg: "85%", sm: "90%", xs: "90%" },
          margin: "0 auto",
          boxSizing: "border-box",
          mt: 10,
          mb: 15,
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            fontWeight: 700,
            fontSize: "30px",
            fontFamily: "optima",
          }}
        >
          New on Reisty
        </Typography>
        <Box sx={{ mt: 7 }}>
          <Grid container spacing={5}>
            {blogs?.slice(0, 3)?.map((blog, index) => (
              <Grid item md={4} key={index}>
                <Link to={`/blogs/${blog?.slug?.current}`}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: 3,
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        height: "120px",
                        width: "100%",
                        bgcolor: "#333",
                        borderRadius: "20px",
                        background: ` url('${
                          blog && urlFor(blog?.image?.asset?._ref).url()
                        }')`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    />
                    <Box sx={{ mt: 2 }}>
                      <Typography
                        sx={{
                          bgcolor: "#EBF0FD",
                          color: "#1A0BD6",
                          width: "fit-content",
                          p: 0.4,
                          borderRadius: "50px",
                          px: 1,
                          fontSize: "10px",
                        }}
                      >
                        {blog?.blogType || "--"}
                      </Typography>
                      <Typography
                        className="one-line-ellipsis"
                        sx={{ mt: 1, color: "#707070", fontWeight: 700 }}
                      >
                        {blog?.name || "--"}
                      </Typography>
                      <Typography
                        className="two-line-ellipsis"
                        sx={{
                          mt: 1,
                          color: "#707070",
                          fontSize: "12px",
                          fontWeight: 300,
                          lineHeight: "15px",
                        }}
                      >
                        {blog?.description || "--"}
                      </Typography>
                      <Box
                        sx={{
                          mt: 1,
                          display: "flex",
                          alignItems: "center",
                          columnGap: 1,
                        }}
                      >
                        <Avatar sx={{ height: "30px", width: "30px" }} />
                        <Box>
                          <Typography
                            sx={{ fontSize: "10px", fontWeight: 500 }}
                          >
                            George Ikegwu
                          </Typography>
                          <Typography
                            sx={{ fontSize: "10px", fontWeight: 200, mt: -0.8 }}
                          >
                            5 mins
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <Box sx={{ bgcolor: "#F6E2E2" }}>
        <Box
          sx={{
            width: { xl: "75%", lg: "85%", sm: "90%", xs: "90%" },
            margin: "0 auto",
            boxSizing: "border-box",
            pt: 8,
            pb: 15,
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: 700,
              fontSize: "30px",
              fontFamily: "optima",
              color: "primary.main",
            }}
          >
            Reisty Guides
          </Typography>
          <Box sx={{ mt: 7 }}>
            <Grid container spacing={4}>
              {blogs?.slice(4, 10)?.map((blog, index) => (
                <Grid item md={6} key={index}>
                  <Link to={`/blogs/${blog?.slug?.current}`}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 3,
                        cursor: "pointer",
                        transition: "0.1s all linear",
                      }}
                    >
                      <Box
                        sx={{
                          height: "120px",
                          width: "35%",
                          bgcolor: "#333",
                          borderRadius: "12px",
                          background: ` url('${
                            blog && urlFor(blog?.image?.asset?._ref).url()
                          }')`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      />
                      <Box sx={{ width: "65%" }}>
                        <Typography
                          sx={{ mt: 1, color: "#707070", fontWeight: 700 }}
                        >
                          {blog?.name || "--"}
                        </Typography>
                        <Box
                          sx={{
                            mt: 1,
                            display: "flex",
                            alignItems: "center",
                            columnGap: 1,
                          }}
                        >
                          <Avatar sx={{ height: "30px", width: "30px" }} />
                          <Box>
                            <Typography
                              sx={{ fontSize: "10px", fontWeight: 500 }}
                            >
                              Joy
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "10px",
                                fontWeight: 200,
                                mt: -0.8,
                              }}
                            >
                              5 mins
                            </Typography>
                          </Box>
                        </Box>
                        <Typography
                          className="three-line-ellipsis"
                          sx={{
                            mt: 1,
                            color: "#707070",
                            fontSize: "12px",
                            fontWeight: 300,
                            lineHeight: "15px",
                          }}
                        >
                          {blog?.description || "--"}
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>
      <Divider sx={{ my: 6 }} />
      <Box sx={{ width: "95%", margin: "0 auto" }}>
        <Box
          sx={{
            bgcolor: "#333",
            height: isMobile ? "250px" : "400px",
            borderRadius: "10px",
            display: "grid",
            placeItems: "center",
            background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('${
              mainBlog && urlFor(mainBlog?.image?.asset?._ref).url()
            }')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Box
            sx={{
              width: { xl: "75%", lg: "85%", sm: "90%", xs: "90%" },
              margin: "0 auto",
            }}
          >
            <Typography
              sx={{
                color: "#D54118",
                bgcolor: "#D541181a",
                width: "fit-content",
                px: 3,
                py: 0.2,
                fontSize: "12px",
              }}
            >
              {mainBlog?.blogType || "--"}
            </Typography>
            <Typography
              sx={{
                mt: 2,
                fontFamily: "optima",
                fontSize: isMobile ? "18px" : "25px",
                lineHeight: isMobile ? "18px" : "28px",
                fontWeight: 700,
                color: "#fff",
                width: "70%",
              }}
            >
              {mainBlog?.name || "--"}
            </Typography>
            <Typography
              className="two-line-ellipsis"
              sx={{
                color: "#fff",
                fontWeight: 300,
                mt: 1,
                width: "85%",
                fontSize: isMobile ? "12px" : "14px",
              }}
            >
              {mainBlog?.description || "--"}
            </Typography>
            <Link to={`/blogs/${mainBlog?.slug?.current}`}>
              <Button
                variant="contained"
                sx={{ width: "100px", mt: isMobile ? 2 : 3 }}
              >
                Read More
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: { xl: "75%", lg: "85%", sm: "90%", xs: "90%" },
          margin: "0 auto",
          boxSizing: "border-box",
          mt: 10,
          mb: 15,
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            fontWeight: 700,
            fontSize: "30px",
            fontFamily: "optima",
          }}
        >
          Restaurant roundups
        </Typography>
        <Box sx={{ mt: 7 }}>
          <Grid container spacing={5}>
            {blogs?.slice(10, 13)?.map((blog, index) => (
              <Grid item md={4} key={index}>
                <Link to={`/blogs/${blog?.slug?.current}`}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: 3,
                      flexDirection: "column",
                      cursor: "pointer",
                      transition: "0.2s all linear",
                      "&:hover": {
                        transform: "scale(0.7)",
                        mt: -3,
                        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                        p: 2,
                        borderRadius: "15px",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        height: "120px",
                        width: "100%",
                        bgcolor: "#333",
                        borderRadius: "20px",
                        background: ` url('${
                          blog && urlFor(blog?.image?.asset?._ref).url()
                        }')`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    />
                    <Box sx={{ mt: 2 }}>
                      <Typography
                        sx={{
                          bgcolor: "#EBF0FD",
                          color: "#1A0BD6",
                          width: "fit-content",
                          p: 0.4,
                          borderRadius: "50px",
                          px: 1,
                          fontSize: "10px",
                        }}
                      >
                        {blog?.blogType || "--"}
                      </Typography>
                      <Typography
                        className="one-line-ellipsis"
                        sx={{ mt: 1, color: "#707070", fontWeight: 700 }}
                      >
                        {blog?.name || "--"}
                      </Typography>
                      <Typography
                        className="two-line-ellipsis"
                        sx={{
                          mt: 1,
                          color: "#707070",
                          fontSize: "12px",
                          fontWeight: 300,
                          lineHeight: "16px",
                        }}
                      >
                        {blog?.description || "--"}
                      </Typography>
                      <Box
                        sx={{
                          mt: 1,
                          display: "flex",
                          alignItems: "center",
                          columnGap: 1,
                        }}
                      >
                        <Avatar sx={{ height: "30px", width: "30px" }} />
                        <Box>
                          <Typography
                            sx={{ fontSize: "10px", fontWeight: 500 }}
                          >
                            George Ikegwu
                          </Typography>
                          <Typography
                            sx={{ fontSize: "10px", fontWeight: 200, mt: -0.8 }}
                          >
                            5 mins
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <Box sx={{ bgcolor: "#F6E2E2" }}>
        <Box
          sx={{
            width: { xl: "75%", lg: "85%", sm: "90%", xs: "100%" },
            margin: "0 auto",
            boxSizing: "border-box",
            pt: isMobile ? 0 : 8,
            pb: isMobile ? 5 : 15,
          }}
        >
          <Box sx={{ mt: isMobile ? 0 : 7 }}>
            <Grid container columnSpacing={4}>
              <Grid item xl ={6} lg={6} md={6} sm={12} xs={12}>
                <Box
                  sx={{
                    height: "230px",
                    width: isMobile ? "100%" : "80%",
                    bgcolor: "#333",
                    borderRadius: "12px",
                    background: `url('${ocean}')`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Box sx={{ p: isMobile && 3 }}>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#000",
                      fontWeight: 700,
                      fontSize: "30px",
                      fontFamily: "optima",
                    }}
                  >
                    Oceans5
                  </Typography>

                  <Typography
                    sx={{
                      mt: 2,
                      color: "#707070",
                      fontSize: "14px",
                      fontWeight: 300,
                      lineHeight: "22px",
                    }}
                  >
                    If you’re in the mood for seafood and stunning waterfront
                    views, Oceans5 is the place to be. This trendy spot offers a
                    happy hour menu filled with fresh seafood options and
                    refreshing drinks at unbeatable prices. Make sure to book
                    your table on Reisty to enjoy discounts on dishes like
                    calamari, shrimp cocktails, and fish tacos paired perfectly
                    with their signature mojitos. View more
                  </Typography>
                  <Link to={"/restaurant/bbafd4d5-e877-4d6c-99c4-c39c85aa9cf1"}>
                    <Button
                      variant="contained"
                      sx={{ mt: 3, px: 4, fontWeight: 300 }}
                    >
                      Book a Reservation{" "}
                    </Button>
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          pt: 10,
          width: { xl: "75%", lg: "85%", sm: "90%", xs: "90%" },
          margin: "0 auto",
          boxSizing: "border-box",
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            fontWeight: 700,
            fontSize: "30px",
            fontFamily: "optima",
          }}
        >
          All Blogs
        </Typography>
        <Grid container spacing={2} sx={{ mt: 5 }}>
          {paginatedBlogs?.map((blog, index) => (
            <Grid item md={3} sm={12} xs={6}>
              <Link to={`/blogs/${blog?.slug?.current}`} key={index}>
                <BlogCard data={blog} />
              </Link>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ display: "grid", placeContent: "center", mt: 3 }}>
          <Pagination
            count={count}
            page={page}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
          />
        </Box>
      </Box>
          </>
        )
      }
  
      <FooterHero />
      <Footer />
    </>
  );
};

export default Blogs;
