import { Button, Typography } from '@mui/material'
import React, { useState } from 'react'
import GoogleIcon from '../../../Assets/icons/gooogle.svg'
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { loginGoogle } from '../../../api';
import Loader from '../Common/Loader';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUser } from '../../../store/userSlice';
import { useSnackbar } from "notistack";
import { Link, useLocation } from "react-router-dom";
import queryString from "query-string";

const GoogleLogin = () => {
      const { enqueueSnackbar } = useSnackbar() 
  const dispatch = useDispatch();
  const navigate = useNavigate()
        const handleAlert = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };

    const location = useLocation();
    const from = location.state?.from?.pathname
  const queryParams = queryString.parse(location.search);
  const { callBack, restaurantName, guest, time, date, deposit } = queryParams;

    const locationState = location.state;



  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const userInfo = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`,
          },
        });
        // console.log(userInfo.data); 
        loginUserWithGoogle(userInfo?.data?.email)
      } catch (error) {
        console.error('Error fetching user info:', error);
             handleAlert("error", `${error}`)
      }
    },
    onFailure: (error) => {
      console.error('Login failed:', error);
           handleAlert("error", `${error}`)
    },
  });

const [isLoading, setIsLoading] = useState(false)

  const loginUserWithGoogle = async (email) =>{
setIsLoading(true)
await loginGoogle(email)
.then((res)=>{
  setIsLoading(false)
  // console.log(res)
  if(res?.data?.status){
       localStorage.setItem(
                  "userInfo",
                  JSON.stringify(res?.data?.result[0]))
                  dispatch(setUser(res?.data?.result[0]))
      if (locationState && locationState.redirectTo) {
      navigate(`${locationState?.redirectTo}`);
    } else {
  
      navigate('/');
    }
               
  }
  else{
         handleAlert("error", `${res?.data?.
error_message
}`)

  }
})
.catch((err)=>{
  setIsLoading(false)
  // console.log(err)
})
  }
  return (
<>
{
  isLoading && <Loader/>
}
          <Button
          onClick={()=>login()}
         
            sx={{
              border: "1px solid #dadada",
              mt: 4,
              borderRadius: "30px",
              height: "40px",
          
            }}
          >
            <img src={GoogleIcon} width={20} />{" "}
            <Typography sx={{ ml: 1, color: "#151515", fontSize:'12px' }}>
              Log in with Google
            </Typography>
          </Button>
</>
  )
}

export default GoogleLogin