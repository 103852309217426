import React from "react";
import Navbar from "../../Common/Navbar";
import Footer from "../Footer";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Typography, Divider, Button } from "@mui/material";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdOutlineChevronLeft } from "react-icons/md";
import { Link } from "react-router-dom";

const Help = () => {
  return (
    <>
      <Navbar />
      <Box
        sx={{
          pt: 15,
          px: 4,
          overflow: "scroll",
    width: {lg:"40%", md:'50%',  sm:'70%', xs:'100%'},
          margin: "0 auto",
          boxSizing: "border-box",
          pb: 5,
        }}
      >
        <Typography sx={{ fontSize: "25px", fontWeight: 500 }}>
          Help and Feedback
        </Typography>
        <Typography sx={{ mt: 1, color: "#6f7f94" }}>
          Get help and support on how to use our services.
        </Typography>
        <Divider sx={{ my: 2 }} />

        <Box sx={{ mt: 3 }}>
          <Typography sx={{ fontSize: "18px", fontWeight: 700 }}>
            Frequently Asked Questions
          </Typography>
          <Box sx={{ mt: 3 }}>
            <Accordion sx={{ borderRadius: 2 }} defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{ fontWeight: 500 }}
              >
                How does Reisty work?
              </AccordionSummary>
              <AccordionDetails sx={{ fontWeight: 300, fontSize: "14px" }}>
                Reisty is your go-to platform for finding and booking great
                restaurants in Lagos. With Reisty reservations, diners can
                explore Reisty restaurants, check availability, and make Reisty
                bookings easily online through our Reisty app or Reisty website.
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ mt: 2, borderRadius: 2 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                sx={{ fontWeight: 500 }}
              >
                Can I cancel or modify my Reisty reservation?
              </AccordionSummary>
              <AccordionDetails sx={{ fontWeight: 300, fontSize: "14px" }}>
                You can cancel or modify your Reisty reservation through the
                Reisty app or the Reisty website. Just Reisty login to your
                account, go to Reisty bookings and follow the prompts to make
                changes.
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ mt: 2, borderRadius: 2 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                sx={{ fontWeight: 500 }}
              >
                Can I view the Reisty menu before making my reservation?
              </AccordionSummary>
              <AccordionDetails sx={{ fontWeight: 300, fontSize: "14px" }}>
                Absolutely! Reisty provides menus for many of the restaurants
                listed on our platform. Customers can view the food options
                available and make an informed decision before making a booking.
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ mt: 2, borderRadius: 2 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                sx={{ fontWeight: 500 }}
              >
                Can I see reviews of Reisty restaurants?
              </AccordionSummary>
              <AccordionDetails sx={{ fontWeight: 300, fontSize: "14px" }}>
                Yes, Reisty diners can read reviews from other Reistars to help
                them choose the best restaurant for their taste and occasion.
                Reviews are available on each Reisty restaurant's profile page.
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ mt: 2, borderRadius: 2 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                sx={{ fontWeight: 500 }}
              >
                How do I make a Reisty reservation through the app?
              </AccordionSummary>
              <AccordionDetails sx={{ fontWeight: 300, fontSize: "14px" }}>
                Making a Reisty reservation through the Reisty app is
                straightforward. Simply download the Reisty app from the App
                Store or Google Play Store, create a Reisty login or sign up,
                choose a Reisty restaurant, select your preferred date and time,
                and confirm your Reisty booking.
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ mt: 2, borderRadius: 2 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                sx={{ fontWeight: 500 }}
              >
                How do I contact Reisty Customer Support or Reisty Office?
              </AccordionSummary>
              <AccordionDetails sx={{ fontWeight: 300, fontSize: "14px" }}>
                If you have any questions or need support, you can reach out to
                Reisty Customer Support through the Reisty contact page on our
                website or directly contact the Reisty Office. Our team is here
                to assist you with any Reisty pricing, reservations, or
                app-related inquiries.
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            my: 4,
          }}
        >
          <Link to="/contact">
            <Button startIcon={<MdOutlineChevronLeft />}>Contact</Button>
          </Link>
          <Link to="/privacy-policy">
            <Button endIcon={<MdKeyboardArrowRight />}>Privacy Policy</Button>
          </Link>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default Help;
