import React, { useEffect, useState } from "react";
import Navbar from "../Components/Common/Navbar";
import Footer from "../Components/Footer/Footer";
import { Avatar, Box, Grid, LinearProgress, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { getBookedReservationDetails } from "../../api";
import Loader from "../Components/Common/Loader";
import { MdCalendarMonth } from "react-icons/md";
import guestIcon from "../../Assets/icons/guest.svg";
import { AiFillClockCircle } from "react-icons/ai";
import userIcon from "../../Assets/icons/user.svg";
import { useSelector } from "react-redux";
import Lottie from "react-lottie";
import anime from "../../Assets/anime/done.json";
import MapContainer from "../Components/Common/MapContainer";
import { MdPendingActions } from "react-icons/md";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: anime,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Booked = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const { id } = useParams();
  const userDetails = useSelector((state) => state?.user?.user);

  const handleGetReservationDetails = async () => {
    setIsLoading(true);
    await getBookedReservationDetails(id)
      .then((res) => {
        setIsLoading(false);
        setData(res?.data?.result[0]);
        // console.log(res);
      })
      .catch((err) => {
        setIsLoading(false);
        // console.log(err);
      });
  };

  useEffect(() => {
    handleGetReservationDetails();
  }, []);

  return (
    <>
      {/* {isLoading && <Loader />} */}
   {
    isLoading ? (
      <>
<Box sx={{height:'100vh', display:'flex', flexDirection:'column', alignContent:'center', justifyContent:'center'}}>

<Typography sx={{textAlign:'center', mb:1, fontWeight:600}}>Confirming your reservation...</Typography>
<Box sx={{width:'50%', margin:'0 auto'}}>

<LinearProgress />
</Box>
</Box>
      </>
    ):(
      <>
   <Navbar />
      <Box
        sx={{
          width: { xl: "75%", lg: "85%", md: "85%", sm: "90%", xs: "95%" },
          margin: "0 auto",
          my: 15,
        }}
      >
        <Grid container spacing={2}>
          <Grid item lg={7} md={6} sm={12} xs={12}>
            <Box sx={{ boxSizing: "border-box" }}>
              <Box sx={{ border: "1px solid #d7d7d7", borderRadius: "12px" }}>
                <Box
                  sx={{
                    p: 3,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent:'center'
                  }}
                >
                  {
                    data?.RestaurantDetails?.RestaurantName === "Nostalgia Lagos" ? (
                      <>
                <MdPendingActions  style={{fontSize:'75px', color:'#c56001'}}/>
                  <Typography sx={{ fontSize: "18px", fontWeight: 700, mt:2,fontFamily:'optima' }}>
       Reservation pending approval
                  </Typography>
                  <Typography>
                    Please check your inbox for a confirmation email.
                  </Typography>
                      </>
                    ):(
                      <>
                         <Lottie options={defaultOptions} width={300} height={200} />
                  <Typography sx={{ fontSize: "18px", fontWeight: 700, fontFamily:'optima' }}>
                    Reservation Booked
                  </Typography>
                  <Typography>
                    Please check your inbox for a confirmation email.
                  </Typography>
                      </>
                    )
                  }
               
                </Box>

                <Box
                  sx={{
                    mt: 2,
                    borderTop: "1px solid #d7d7d7",
                    p: 3,
                    display: "flex",
                    columnGap: 4,
                  }}
                >
                  <Avatar
                    variant="rounded"
                    src={data?.RestaurantDetails?.ImageUrl}
                    sx={{ height: "80px", width: "80px" }}
                  />
                  <Box>
                    <Typography sx={{ fontWeight: 600 }}>
                      {data?.RestaurantDetails?.RestaurantName}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 1,
                        mt: 0.5,
                      }}
                    >
                      <MdCalendarMonth />
                      <Typography sx={{ fontSize: "12px", fontWeight: 300 }}>
                        {formatDate(data?.Date)}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 1,
                        mt: 0.5,
                      }}
                    >
                      <img src={guestIcon} width={15} />
                      <Typography sx={{ fontSize: "12px", fontWeight: 300 }}>
                        {data?.GuestNum} guest(s)
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 1,
                        mt: 0.5,
                      }}
                    >
                      <AiFillClockCircle />
                      <Typography sx={{ fontSize: "12px", fontWeight: 300 }}>
                        {data?.Time}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 1,
                        mt: 1,
                      }}
                    >
                      <img src={userIcon} width={15} />
                      <Typography
                        sx={{ fontSize: "12px", fontWeight: 300 }}
                      >{`${userDetails?.first_name || "--"} ${userDetails?.last_name || "--"}`}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={5} md={6} sm={12} xs={12}>
            <Box sx={{ boxSizing: "border-box" }}>
              <Box
                sx={{ border: "1px solid #d7d7d7", borderRadius: "12px" }}
              ></Box>
              <Box sx={{ mt: 3, height: "300px", overflow: "hidden" }}>
                <MapContainer
                  variant="map"
                  restaurantAddress={data?.RestaurantDetails?.Address}
                />
              </Box>
              <Box
                sx={{
                  p: 3,
                  boxSizing: "border-box",
                  border: "1px solid #d7d7d7",
                }}
              >
                <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>
                  Locate {data?.RestaurantDetails?.RestaurantName}
                </Typography>

                <Typography sx={{ mt: 0.5, fontSize: "12px" }}>
                  {data?.RestaurantDetails?.Address}
                </Typography>

                <Box sx={{ mt: 3 }}>
                  <a href={`tel:${data?.RestaurantDetails?.PhoneNumber}`}>
                    <Typography color={"primary"}>
                      {data?.RestaurantDetails?.PhoneNumber}
                    </Typography>
                  </a>
                  <Box sx={{ mt: 2 }}>
                    <MapContainer
                      variant="button"
                      restaurantAddress={data?.RestaurantDetails?.Address}
                    />
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <a
                      href={data?.RestaurantDetails?.Restaurantwebsite}
                      target="_blank"
                    >
                      <Typography color="primary">
                        Restaurant Website
                      </Typography>
                    </a>
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <a href={`mailto:${data?.RestaurantDetails?.EmailAddress}`}>
                      <Typography color="primary">
                        {data?.RestaurantDetails?.Email}
                      </Typography>
                    </a>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Footer />
      </>
    )
   }
    </>
  );
};

export default Booked;

function formatDate(inputDate) {
  // Create a Date object
  const date = new Date(inputDate);

  // Define an array of day and month names
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednessday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthsOfYear = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Extract the day of the week, month, and day of the month
  const dayOfWeek = daysOfWeek[date.getDay()];
  const month = monthsOfYear[date.getMonth()];
  const dayOfMonth = date.getDate();

  // Format the date string
  const formattedDate = `${dayOfWeek}, ${month} ${dayOfMonth}`;

  return formattedDate;
}
