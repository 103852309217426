import {
  Box,
  Button,
  IconButton,
  InputLabel,
  Typography,
  Modal,
  LinearProgress,
} from "@mui/material";
import Lottie from "react-lottie";
import React, { useEffect, useState } from "react";
import Information from "./Information";
import { Link, useLocation } from "react-router-dom";
import queryString from "query-string";
import { IoIosArrowRoundBack } from "react-icons/io";
import BounceLoader from "react-spinners/BounceLoader";
import { BiCalendarExclamation } from "react-icons/bi";
import ReservationDetails from "./ReservationDetails";
import { cancelReservation, getOfflineBooking } from "../../api";
import { MdOutlineFreeCancellation } from "react-icons/md";
import anime from '../../Assets/anime/cancel.json'

const override = {
  display: "block",
  margin: "auto",
  borderColor: "#BC172F",
};

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {sm:350, xs:300},
    bgcolor: "background.paper",
borderRadius:'10px',
    boxShadow: 24,
    p: 4,
  };

const ManageReservation = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const { BookingID } = queryParams;
  const [activeStep, setActiveStep] = useState(0);

    const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: anime,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleGetBooking = async () => {
    setIsLoading(true);

    await getOfflineBooking(BookingID)
      .then((res) => {
        setIsLoading(false);
        // console.log(res);
        if (res?.data?.status) {
          setData(res?.data?.result?.Item2);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        // console.log(err);
      });
  };

  useEffect(() => {
    handleGetBooking();
  }, []);

  const [firstname, lastname, number, email] = data?.GuestInfo
    ? data?.GuestInfo?.split(",")
    : ["", "", "", ""];

  const user = {
    firstname,
    lastname,
    number,
    email,
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "My Awesome Website",
          text: "Check out this amazing website!",
          url: "https://www.example.com",
        });
        // console.log("Content shared successfully");
      } catch (error) {
        console.error("Error sharing content", error);
      }
    } else {
      alert("Web Share API is not supported in your browser.");
    }
  };

  const [cancelLoading, setCancelLoading] = useState(false)
  const [errMessage, setErrMessage] = useState(null)
  const [isCanceled , setIsCanceled] = useState(false)

  const handleCancelReservation = async () =>{
    setErrMessage(null)
setCancelLoading(true);
await cancelReservation(BookingID)
.then((res)=>{
  setCancelLoading(false);

// console.log(res)
if(res?.data?.status){
  setIsCanceled(true);
}else{
  setErrMessage(res?.data?.error_message
)
}
}).catch((err)=>{
    setCancelLoading(false);
// console.log(err)
  setErrMessage(err?.message
)
})
  }


  return (
    <>
      <Box
        sx={{
          backgroundColor: "rgba(188, 23, 47, 0.05)",
          height: "100vh",
          backgroundSize: "contain",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            margin: "0 auto",
            width: { xl: "30%", lg: "35%", md: "50%", sm: "65%", xs: "100%" },
            bgcolor: "#fff",
            height: "100vh",
          }}
        >
          {/* <Button
                  onClick={handleShare}
                  // onClick={()=>setActiveStep(1)}
                    variant="contained"
                    fullWidth
                    sx={{ mt: 3, borderRadius: "50px", py: 2 }}
                  >
                    Modify Reservation
                  </Button> */}
          {isLoading ? (
            <>
              <Box sx={{ height: "100%", display: "grid" }}>
                <BounceLoader
                  color={"#BC172F"}
                  loading={true}
                  cssOverride={override}
                  size={100}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </Box>
            </>
          ) : (
            <>
              {!data || data?.length === 0 ? (
                <>
                  <Box
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <BiCalendarExclamation style={{ fontSize: "50px" }} />
                    <Typography
                      color="primary"
                      sx={{ mt: 3, fontSize: "16px", fontWeight: 700 }}
                    >
                      Reservation Not Found
                    </Typography>
                    <Typography
                      sx={{ mt: 1, fontSize: "12px", fontWeight: 300 }}
                    >
                      The reservation might have been canceled or completed
                    </Typography>
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    sx={{
                      borderBottom: "0.5px solid #d7d7d7",
                      height: "10vh",
                      display: "flex",

                      boxSizing: "border-box",
                      px: 2,
                      justifyContent: "space-between",
                      alignItems: "center",
                      transition: "0.2s all linear",
                    }}
                  >
                    {activeStep > 0 && (
                      <IconButton onClick={() => setActiveStep(activeStep - 1)}>
                        <IoIosArrowRoundBack />
                      </IconButton>
                    )}
                    <Box
                      sx={{
                        width: activeStep > 0 ? "95%" : "100%",
                        transition: "0.2s all ease-in-out",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: 600,
                          textAlign: "center",
                        }}
                      >
                        {data?.RestaurantDetails?.RestaurantName}
                      </Typography>
                      <InputLabel
                        sx={{ fontSize: "10px", textAlign: "center", px: 2 }}
                      >
                        {data?.RestaurantDetails?.Address}
                      </InputLabel>
                    </Box>
                  </Box>
              {
                isCanceled ? (
                  <>
   <Box
                    sx={{
                      boxSizing: "border-box",

                      height: "88vh",
                      overflow: "scroll",
                    }}
                    className="hide_scrollbar"
                  >
     <Lottie options={defaultOptions}  width={150}  height={150}/>
     <Box sx={{mt:4}} align="center">
      <Typography sx={{px:3, textAlign:'center'}}> Your reservation for <b>         {data && formatDate(data?.Date)}</b> has been canceled successfully</Typography>
<Link to="/">
      <Button sx={{mt:4, py:1, }} variant="contained">Explore Restaurants</Button></Link>
     </Box>

                  </Box>
                  </>
                ):(
                  <>
    <Box
                    sx={{
                      boxSizing: "border-box",

                      height: "88vh",
                      overflow: "scroll",
                    }}
                    className="hide_scrollbar"
                  >
                    {activeStep === 0 && (
                      <>
                        <Typography sx={{ fontWeight: 600, px: 3, mt: 3 }}>
                          Reservation Details
                        </Typography>
                        <Box sx={{ mt: 3, px: 3 }}>
                          <Typography>{data?.GuestNum} guests</Typography>
                          <Typography sx={{ mt: 2 }}>
                            {data && formatDate(data?.Date)}
                          </Typography>
                          <Typography sx={{ mt: 2 }}>{data?.Time}</Typography>

                          <Box sx={{ mt: 3 }}>
                            <Typography
                              sx={{ fontWeight: 600, fontSize: "14px" }}
                            >
                              Your Details
                            </Typography>

                            <Typography sx={{ mt: 2 }}>
                              {firstname} {lastname}
                            </Typography>
                            <Typography sx={{ mt: 2 }}>
                              {data && maskEmail(email)}
                            </Typography>
                            <Typography sx={{ mt: 2 }}>
                              {data && maskPhoneNumber(number)}
                            </Typography>
                          </Box>
                          <Typography variant="h4" color="error" sx={{ mt: 5, cursor:'pointer', transition:'0.2s all linear', "&:hover":{textDecoration:'underline', fontWeight:700} }} onClick={handleOpen}>
                            Cancel Reservation?
                          </Typography>

                          <Button
                            // onClick={handleShare}
                            onClick={() => setActiveStep(1)}
                            variant="contained"
                            fullWidth
                            sx={{ mt: 3, borderRadius: "50px", py: 2 }}
                          >
                            Modify Reservation
                          </Button>
                        </Box>
                      </>
                    )}
                    {activeStep === 1 && (
                      <>
                        <ReservationDetails
                          action={() => setActiveStep(2)}
                          data={data}
                          user={user}
                        />
                      </>
                    )}
                  </Box>
                  </>
                )
              }
                </>
              )}
            </>
          )}
        </Box>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
   <Box sx={{display:'flex', columnGap:2, alignItems:'center', flexDirection:'column'}}>
       <MdOutlineFreeCancellation style={{fontSize:'45px', color:'#BC172F'}} />
           <Typography id="modal-modal-title" variant="h6" component="h2" sx={{fontSize:'18px', textAlign:'center'}}>
  Do you really want to cancel this reservation?
          </Typography>
   </Box>
{
  cancelLoading ? (
    <>
    <Box sx={{mt:3}}>
      <Typography sx={{textAlign:'center', }} color={"primary"}>Cancelling reservation...</Typography>
      <LinearProgress/>
    </Box>
    </>
  ):(
    <>
           <Box sx={{display:'flex', alignItems:'center', columnGap:3, mt:3, justifyContent:'space-between'}}>
        <Button variant="contained" onClick={handleClose} sx={{fontWeight:400, fontSize:'12px', height:'40px', borderRadius:'50px', lineHeight:'13px'}}>
          No, keep the reservation
        </Button>
        <Button variant="outlined"  sx={{fontWeight:400, fontSize:'12px', height:'40px', borderRadius:'50px', lineHeight:'13px'}} onClick={handleCancelReservation}>
   Yes, cancel the reservation
        </Button>
       </Box>
       {
        errMessage && <Typography color="error" sx={{fontSize:'10px', textAlign:'center', mt:1, p:0.2, px:1, }}><b>Error:</b> {errMessage}</Typography>
       }
    </>
  )
}
        </Box>
      </Modal>
    </>
  );
};

export default ManageReservation;

const maskEmail = (email) => {
  if (email) {
    const [localPart, domain] = email?.split("@");
    const maskedLocalPart =
      localPart?.slice(0, 3) + "*".repeat(localPart?.length - 3);
    return `${maskedLocalPart}@${domain}`;
  }
};

const maskPhoneNumber = (phoneNumber) => {
  if (phoneNumber) {
    return phoneNumber.slice(0, 3) + "********" + phoneNumber.slice(-2);
  }
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { weekday: "long", month: "long", day: "numeric" };
  return new Intl.DateTimeFormat("en-US", options).format(date);
};
