import React from "react";
import {
  TextField,
  InputLabel,
  createTheme,
  ThemeProvider,
} from "@mui/material";



const CustomInput = ({small,type,
...props
}) => {
  return (
    <>

    
        <TextField
              {...props}
     margin="dense"
              InputProps={{
                  style: {
                    fontFamily: "outfit",
                    fontSize: "13px",
                    borderRadius: "10px",
                    offset: " 1px solid #737373",
                    fontWeight: 500,
                    height:small && '45px',
                    
                  },
                  inputMode:type === "number" && "numeric"
                }}
        />

    </>
  );
};

export default CustomInput;
