
import { ThemeProvider } from '@mui/material';
import './App.css';
import { Routes } from './Routes';
import Theme from './Themes';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from "./store/store";
import { SnackbarProvider } from 'notistack';
import ErrorAlert from './Main/Components/Common/ErrorAlert';
import SuccessAlert from './Main/Components/Common/SuccessAlert';
import ScrollToTop from './utils/ScrollToTop';
import { HelmetProvider } from 'react-helmet-async';

function App() {
    const helmetContext = {};

  return (
   <>
<HelmetProvider context={helmetContext}>
        <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={Theme}>
      <SnackbarProvider     anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        Components={{ error: ErrorAlert, success: SuccessAlert }}
          
          maxSnack={3}>
   
    <Routes/>
    <ScrollToTop/>
   </SnackbarProvider>
   </ThemeProvider>
   </PersistGate>
   </Provider>
   </HelmetProvider>
   </>
  );
}

export default App;
