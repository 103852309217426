import React, { useEffect, useState } from "react";
import { MdExpandMore } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import queryString from "query-string";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  InputLabel,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
  Drawer,
  Button,
  Popover,
} from "@mui/material";
import AmenitiesData from "../../../Assets/Dataa/Amenities";

const Amenities = () => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const { Text } = queryParams;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [params, setParams] = useState("");

  useEffect(() => {
    setParams(Text);
  }, [Text]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPoper = Boolean(anchorEl);
  const id = openPoper ? "simple-popover" : undefined;
  return (
    <>
      {/* <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  columnGap: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: 2,
                    width: "100%",
                    overflow: "scroll",
                  }}
                >
                  {Amenities?.map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        mt: 1.5,
                      }}
                      onClick={() => setSelectedAmenity(item?.title)}
                    >
                      <IconButton sx={{}}>
                        <img
                          src={
                            selectedAmenity === item?.title
                              ? item?.icon2
                              : item?.icon
                          }
                          width={20}
                        />
                      </IconButton>
                      <Typography
                        sx={{
                          textTransform: "capitalize",
                          fontSize: "10px",
                          ...(selectedAmenity === item?.title && {
                            color: "#BC172F",
                          }),
                        }}
                      >
                        {item?.title}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                <Box align="right">
                  <IconButton onClick={toggleDrawer(true)}>
                    <MdExpandMore />
                  </IconButton>
                </Box>
              </Box> */}

      <Box
      aria-describedby={id}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          columnGap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: 2,
            width: "80%",
            overflow: "scroll",
            flexShrink: "initial",
          }}
        >
          {AmenitiesData?.map((item, index) => (
            <Link to={`/restaurant/find?Text=${item?.title}`}>
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  mt: 1.5,
                }}
                // onClick={() => setParams(item?.title)}
              >
                <IconButton sx={{}}>
                  <img
                    src={params === item?.title ? item?.icon2 : item?.icon}
                    width={20}
                    height={20}
                  />
                </IconButton>
                <Typography
                  sx={{
                    textTransform: "capitalize",
                    fontSize: "12px",
                    ...(params === item?.title && {
                      color: "#BC172F",
                    }),
                  }}
                >
                  {item?.title}
                </Typography>
              </Box>
            </Link>
          ))}
        </Box>
        <Box>
          <IconButton onClick={handleClick}>
            <MdExpandMore />
          </IconButton>
        </Box>
      </Box>

      {/* <Drawer open={open} onClose={toggleDrawer(false)}  anchor="top">
<Box sx={{ bgcolor:'#fff', width:'100vw', p:3, boxSizing:'border-box'}}>
  <Box sx={{mb:1}}>
    <IconButton onClick={toggleDrawer(false)}>
<MdOutlineKeyboardBackspace style={{color:'#151515'}} /> 
    </IconButton>
  </Box>
    <Box
    className="hide_scrollbar"
                sx={{
        
                  height:'250px',
                  overflow:'scroll'
                }}
              >
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns:'auto auto auto auto auto' ,
                    alignItems: "center",
                    columnGap: 2,
                    width: "100%",
      
                  }}
                >
                  {Amenities?.map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        mt: 1.5,
                      }}
                      onClick={() => setSelectedAmenity(item?.title)}
                    >
                      <IconButton sx={{}}>
                        <img
                          src={
                            selectedAmenity === item?.title
                              ? item?.icon2
                              : item?.icon
                          }
                          width={20}
                        />
                      </IconButton>
                      <Typography
                        sx={{
                          textTransform: "capitalize",
                          fontSize: "10px",
                          ...(selectedAmenity === item?.title && {
                            color: "#BC172F",
                          }),
                        }}
                      >
                        {item?.title}
                      </Typography>
                    </Box>
                  ))}
                </Box>
            
              </Box>
<Box sx={{my:4}}  align="center">
      <Location
                  handleUpdateAddress={handleUpdateAddress}
                  button={true}
                />
</Box>
              <Box align="center"  sx={{mt:3}}>
                <Button  sx={{border:'2px solid #BC172F', height:'40px', px:4, color:'#BC172F', borderRadius:'50px'}}>Search</Button>
              </Box>
</Box>
      </Drawer> */}

      <Popover
        id={id}
        anchorReference="anchorPosition"
        open={openPoper}
        anchorEl={anchorEl}
        onClose={handleClose}
  anchorPosition={{ top: 90, left: 890 }}
  anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'left',
  }}
  transformOrigin={{
    vertical: 'top',
    horizontal: 'left',
  }}
  elevation={1}
      >
          <Box
    className="hide_scrollbar"
                sx={{
        p:2,
                  // height:'250px',
                  overflow:'scroll',
                  
                }}
              >
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns:'auto auto auto auto auto auto auto'  ,
                    alignItems: "center",
                    columnGap: 2,
                    width: "100%",
      
                  }}
                >
                  {AmenitiesData?.map((item, index) => (
                  <Link to={`/restaurant/find?Text=${item?.title}`}>
                      <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        mt: 1.5,
                      }}
                      // onClick={() => setSelectedAmenity(item?.title)}
                    >
                      <IconButton sx={{}}>
                        <img
                          src={
                            params === item?.title
                              ? item?.icon2
                              : item?.icon
                          }
                          width={20}
                          height={20}
                        />
                      </IconButton>
                      <Typography
                        sx={{
                          textTransform: "capitalize",
                          fontSize: "10px",
                          ...(params === item?.title && {
                            color: "#BC172F",
                          }),
                        }}
                      >
                        {item?.title}
                      </Typography>
                    </Box>
                  </Link>
                  ))}
                </Box>
            
              </Box>
      </Popover>
    </>
  );
};

export default Amenities;
