import { useRoutes } from "react-router-dom";
// import { lazy} from "react";
import Login from "../Main/Pages/Auth/Login";
import Home from "../Main/Pages/Home";
import Signup from "../Main/Pages/Auth/Signup";
import Profile from "../Main/Pages/Profile";
import { Reservation, Profile as IndexPage, HelpSupport } from "../Main/Components/Profile/Pages";
import Help from "../Main/Components/Footer/FooterPages.jsx/Help";
import Contact from "../Main/Components/Footer/FooterPages.jsx/Contact";
import Privacy from "../Main/Components/Footer/FooterPages.jsx/Privacy";
import Terms from "../Main/Components/Footer/FooterPages.jsx/Terms";
import Restaurants from "../Main/Pages/Restaurants";
import Booking from "../Main/Pages/Booking";
import Booked from "../Main/Pages/Booked";
import Verify from "../Main/Pages/Auth/Verify";
import FindRestaurants from "../Main/Pages/FindRestaurants";
import Blogs from "../Main/Pages/Blogs";
import FeaturedMain from "../Main/Pages/FeaturedMain";
import BestRestaurantMain from "../Main/Pages/BestRestaurantMain";
import Rating from "../Main/Pages/Rating";
import ManageReservation from "../Widget/ManageReservation/ManageReservation";
import About from "../Main/Components/Footer/FooterPages.jsx/About";
import BlogOverview from "../Main/Pages/BlogOverview";
import ReservationWidget from "../Widget/Restaurant/ReservationWidget";
import InformationWidget from "../Widget/Restaurant/InformationWidget";
import InformationWidgetUser from "../Widget/Restaurant/InformationWidgetUser";
import NotFound from "../Main/Pages/NotFound";
import { motion } from "framer-motion";



// export const IndexPage = lazy(()=> import("../Main/Pages/Home"));


const slideInVariants = {
  initial: { x: "-100vw" },
  animate: { x: 0 },
  exit: { x: "-100vw" },
};
  const fadeInOutVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  };



export function Routes () {
  let element = useRoutes([
{
path:'*',
element:<NotFound/>
},
    {
      path:'/',
      element:<Home/>
    },

    {
path:'/manage-reservation',
element:<ManageReservation/>
    },
    {
      path:"/login",
         element: (
        <motion.div
          variants={slideInVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
        >
          <Login />
        </motion.div>
      ),
    },
    {
      path:"/verify-email/:email",
      element:<Verify/>
    },
    {
      path:'/signup',
      element:(
           <motion.div
          variants={slideInVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
        >
      <Signup/>
</motion.div>
      )
    },
    {
      path:'/help',
      element:<Help/>
    },
    {
      path:'/about',
      element:<About/>
    },
    {
      path:'/contact',
      element:<Contact/>
    },
    {
      path:'/privacy-policy',
      element:<Privacy/>
    },
    {
      path:'/terms',
      element:<Terms/>
    },
    {
      path:'/rating',
      element:<Rating/>
    },
    {
      path:'/restaurant/:id',
      element:    <motion.div
          variants={fadeInOutVariants}
          initial="initial"
          animate="animate"
          exit="exit"
        ><Restaurants/></motion.div>
    },
    {
      path:'/blogs',
      element:<Blogs/>
    },
    {
      path:'/blogs/:id',
      element:<BlogOverview/>
    },
    {
path:"/restaurant/:restaurantName/:id/booking",
element:<Booking/>
    },
    {
path:"/restaurant/find",
element:<FindRestaurants/>
    },
    {
path:"/reservation/booked/:id",
element:<Booked/>
    },
    {
path:"/restaurants/featured",
element:<FeaturedMain/>
    },
    {
path:"/restaurants/best-restaurants",
element:<BestRestaurantMain/>
    },
          {
        path:"/:name/reservation",
        element:<ReservationWidget/>
      },
          {
        path:"/:name/reservation/guest-booking",
        element:<InformationWidget/>
      },
          {
        path:"/reservation/user",
        element:<InformationWidgetUser/>
      },
    {
     
      element:<Profile/>,
    children:[
      {
      path:'/profile',
      element:<IndexPage/>
      },
      {
        path:'/profile/reservation',
        element:<Reservation/>
      },
      {
        path:"/profile/help-support",
        element:<HelpSupport/>
      },

    ]
    }

  ])



  return element
}