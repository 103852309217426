import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import BlogData from '../../Assets/Dataa/BlogData'
import Navbar from '../Components/Common/Navbar'
import Footer from '../Components/Footer/Footer'
import { Box, Button, Typography, IconButton, Divider, LinearProgress } from '@mui/material'
import BlockContent from "@sanity/block-content-to-react"
import { MdOutlineChevronLeft } from "react-icons/md";
import Client from '../../utils/Client'
import { urlFor } from '../../utils/SanityImageClient'
import { format } from 'date-fns';
import { FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { IoLogoFacebook } from "react-icons/io";
import { IoShareSocial } from "react-icons/io5";


const BlogOverview = () => {
  const [blogs, setBlogs] = useState([]);
   const [singlePost, setSinglePost] = useState(null)
     const [isLoading, setIsLoading] = useState(false)
   const { id } = useParams();

      useEffect(() => {
  Client.fetch(`*[slug.current == "${id}"] {
name,
    slug, blogType,date, description,image,details, readDuration
}`).then((data) => setSinglePost(data[0])).catch(console.error)
}, [id])

  useEffect(() => {
    setIsLoading(true)
    Client.fetch(
      `*[_type == "event"] {
    name,
    slug, blogType,date, description,image,details, readDuration}
`
    )
      .then((data) =>{
 setBlogs(data);
 setIsLoading(false)
      })
      .catch((err)=>{
        console.log(err);
        setIsLoading(false)
      });
  }, [id]);

  const [filteredPosts,  setFilteredPosts] = useState(null)

  useEffect(()=>{
const filtered =  blogs?.filter((item) => item?.slug?.current !== id);
setFilteredPosts(filtered)
  },[id, blogs])

  console.log(singlePost, "ain")
  return (
<>
<Navbar/>
  {
    (!singlePost ||  singlePost?.length === 0) ? (
      <>
<Box sx={{display:'grid', placeItems:'center', height:'70vh'}}>
  <LinearProgress sx={{width:'200px'}} />
</Box>
      </>
    ):(
      <>
   <Box sx={{ pt: 10,      width: { xl: "55%", lg: "65%", sm: "90%", xs: "90%" }, margin: "0 auto" }}>
        <Box
          sx={{
            bgcolor: "#333",
            background:` url('${singlePost?.image && urlFor(singlePost?.image?.asset?._ref).url()}')`,
            backgroundSize:'cover',
            backgroundPosition:'center',
            height: "320px",
            borderRadius: "10px",
            display: "grid",
            placeItems: "center",
          }}
        >
        
        </Box>
      </Box>

        <Box
            sx={{
              width: { xl: "55%", lg: "65%", sm: "90%", xs: "90%" },
              margin: "0 auto",
              my:5
            }}
          >
               <Typography
              sx={{
                color: "#D54118",
                bgcolor: "#D541181a",
                width: "fit-content",
                px: 3,
                py: 0.2,
                fontSize: "10px",
                borderRadius:'5px'
              }}
            >
              {singlePost?.blogType || "--"}
            </Typography>
           <Typography
              sx={{
                mt: 2,
                fontFamily: "optima",
                fontSize: "25px",
                lineHeight: "28px",
                fontWeight: 700,
                textAlign:'center'
              }}
            >
        {singlePost?.name || "--"}
            </Typography>
                  <Typography sx={{  fontWeight: 300, mt: 1 , textAlign:'center', fontSize:'12px', lineHeight:"15px"}}>
              {singlePost?.description || "--"}
            </Typography>
                  <Typography sx={{  fontWeight: 500, mt: 2 , textAlign:'center', fontSize:'12px',    color: "#D54118",}}>
              { singlePost?.date && formatDate(singlePost?.date || "--")}
         
            </Typography>

                <Box className="custom-block-content" sx={{mt:3, fontSize:{md:'14px', sm:'12px', xs:'12px'}}}>
             <BlockContent
      blocks={singlePost?.details}
      projectId="l139ea83"
      dataset="production"
    />
          </Box>

          <Box sx={{mt:5}}>
            <Typography>Share this article</Typography>
            <Box sx={{mt:1}}>
              <IconButton sx={{color:'#000', fontSize:'17px'}}><IoLogoFacebook /></IconButton>
              <IconButton sx={{color:'#000',  fontSize:'17px'}}><FaXTwitter /></IconButton>
              <IconButton sx={{color:'#000',  fontSize:'17px'}}><FaLinkedin /></IconButton>
              <IconButton sx={{color:'#000',  fontSize:'17px'}}><IoShareSocial /></IconButton>
            </Box>
          </Box>

          <Divider sx={{my:3}}/>
          </Box>



    <Box
        sx={{
          pt: 15,
          px: 4,
          overflow: "scroll",
          width: {lg:"40%", md:'50%',  sm:'70%', xs:'100%'},
          margin: "0 auto",
          boxSizing: "border-box",
        }}
      >


   <Box align="center" sx={{my:5}}>
    <Link to="/blogs">
      <Button startIcon={<MdOutlineChevronLeft />} sx={{fontWeight:700, cursor:'pointer', }}>See all posts</Button>
    </Link>
   </Box>
      </Box>
      </>
    )}

<Footer/>
</>
  )
}

export default BlogOverview

const formatDate = (dateString) => {
  // const date = new Date(dateString);
  
  // Format the date
const formattedDate = format(new Date(dateString), 'MMMM dd, yyyy hh:mm a');
  
  return formattedDate;
};

