import {
  Box,
  Grid,
  InputLabel,
  Typography,
  ListItemButton,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomInput from "../../Main/Components/Custom/CustomInput";
import BookingData from "../../Assets/Dataa/BookingData";
import anime from "../../Assets/anime/done.json";
import Lottie from "react-lottie";
import { manageReservationOffline } from "../../api";
import Loader from "../../Main/Components/Common/Loader";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: anime,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Information = ({ data, date, guest, time, user }) => {
  const [Dietary, setDietary] = useState("");
  const [occasion, setOccasion] = useState("");
  const [specialRequest, setSpecialRequest] = useState("");
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false)


  const capitalizeFirstLetter = (string) => {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// console.log(user)
  useEffect(()=>{
setLastName(user?.lastname);
setFirstName(user?.firstname)
setEmail(user?.email)
setPhoneNumber(user?.number);
setOccasion(capitalizeFirstLetter(data?.OccasionCelebrating))
setDietary(data?.GroupMemberDietaryRestriction)
setSpecialRequest(data?.SpecialRequest)

  },[user, data])


  const handleManageReservation = async () =>{
setIsLoading(true)
await manageReservationOffline(data?.
BookingItemId, guest, date, time, specialRequest, Dietary, firstName, lastName, email, phoneNumber, occasion
)
.then((res)=>{
  setIsLoading(false)
  if(res?.data?.status){
    setIsSuccessful(true)
  }
  // console.log(res)
}).catch((err)=>{
    setIsLoading(false)
  // console.log(err)
})
  }


  return (
    <>
    {isLoading && <Loader/>}
      {isSuccessful ? (
        <>
          <Box
            sx={{
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mt:4
            }}
          >
            <Lottie options={defaultOptions} width={400} height={200} />
            <Typography sx={{ fontSize: "18px", fontWeight: 700 }}>
              Reservation Modified
            </Typography>
            <Typography>
              Please check your inbox for a confirmation email.
            </Typography>
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ p: 3, boxSizing: "border-box" }}>
            <Typography sx={{ fontWeight: 600, mb: 2 }}>
              Personal Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item lg={6} xs={6}>
                <InputLabel sx={{ fontSize: "12px" }}>First Name</InputLabel>
                <CustomInput
                  fullWidth
                  small
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Grid>
              <Grid item lg={6} xs={6}>
                <InputLabel sx={{ fontSize: "12px" }}>Last Name</InputLabel>
                <CustomInput
                  fullWidth
                  small
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Grid>
              <Grid item lg={12} xs={12}>
                <InputLabel sx={{ fontSize: "12px" }}>Phone Number</InputLabel>
                <CustomInput
                  fullWidth
                  small
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </Grid>
              <Grid item lg={12} xs={12}>
                <InputLabel sx={{ fontSize: "12px" }}>
                  {" "}
                  Email Address
                </InputLabel>
                <CustomInput
                  fullWidth
                  small
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
            </Grid>
            <Typography sx={{ fontWeight: 600, mb: 1, mt: 4 }}>
              Lets help you plan your visit
            </Typography>
            <Typography sx={{ fontSize: "12px", color: "#333" }}>
              To aid The GoodLife Restaurant in organizing your visit, kindly
              provide answers to the questions below.
            </Typography>
            <Box
              sx={{
                mt: 2,
                borderRadius: "10px",
                border: "1px solid #d7d7d7",
                p: 2,
              }}
            >
              <Typography sx={{ fontWeight: 500, fontSize: "12px" }}>
                What occasion are you celebrating?
              </Typography>

              <Box sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                  {BookingData?.map((item, index) => {
                    const isSelected = item?.title === occasion;
                    return (
                      <Grid item lg={6} xs={6} key={index}>
                        <ListItemButton
                          selected={isSelected}
                          onClick={() => setOccasion(item?.title)}
                          sx={{
                            width: "100%",
                            display: "flex",
                            columnGap: 2,
                            p: 2,
                            border: "1px solid #d7d7d7",
                            borderRadius: "8px",
                            ...(isSelected && {
                              border: "1px solid #BC172F",
                            }),
                          }}
                        >
                          <img src={isSelected ? item?.icon2 : item?.icon1} />
                          <Typography
                            color={isSelected && "primary"}
                            sx={{ fontSize: "12px" }}
                          >
                            {item?.title}
                          </Typography>
                        </ListItemButton>
                      </Grid>
                    );
                  })}
                </Grid>

                <Box sx={{ mt: 3 }}>
                  <Typography sx={{ fontWeight: 500, fontSize: "12px" }}>
                    Do any members of your group have specific dietary
                    restrictions?
                  </Typography>

                  <CustomInput
                    value={Dietary}
                    onChange={(e) => setDietary(e.target.value)}
                    placeholder="Your  input here"
                    multiline
                    fullWidth
                    margin="dense"
                    minRows={3}
                  />
                </Box>
                <Box sx={{ mt: 3 }}>
                  <Typography sx={{ fontWeight: 500, fontSize: "12px" }}>
                    Do have any special request?
                  </Typography>

                  <CustomInput
                    value={specialRequest}
                    onChange={(e) => setSpecialRequest(e.target.value)}
                    placeholder="Your  input here"
                    multiline
                    fullWidth
                    margin="dense"
                    minRows={2}
                  />
                </Box>
              </Box>
            </Box>
            <Button
disabled={!firstName || !lastName || !email || !phoneNumber}
              onClick={handleManageReservation}
              variant="contained"
              fullWidth
              sx={{ mt: 5, borderRadius: "50px", py: 2 }}
            >
              Modify Reservation
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

export default Information;
