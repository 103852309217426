import React from "react";
import Navbar from "../../Common/Navbar";
import Footer from "../Footer";
import { Box, Typography, Divider ,  Button} from "@mui/material";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdOutlineChevronLeft } from "react-icons/md";
import { Link } from 'react-router-dom';
import SEO from "../../../../utils/SEO";

const Contact = () => {
  return (
    <>
        <SEO
            title="Contact Reisty - Get Support for Your Restaurant Bookings in Lagos"
        description="Need assistance with your restaurant reservations? Contact Reisty for support and information on how to make the most of your dining experiences in Lagos."
        name="Reisty"
        type="article"
                canonicalUrl="https:/reisty.com/contact"
    />
    
      <Navbar />
      <Box
        sx={{
          pt: 15,
          px: 4,
   
          overflow: "scroll",
            width: {lg:"40%", md:'50%',  sm:'70%', xs:'100%'},
          margin: "0 auto",
          boxSizing: "border-box",
        }}
      >
        <Typography sx={{ fontSize: "25px", fontWeight: 500 }}>
          Contact
        </Typography>
        <Typography sx={{ mt: 1, color: "#6f7f94" }}>
          Contact us for any questions or concerns about our services.
        </Typography>
        <Divider sx={{ my: 2 }} />

        <Box sx={{ mt: 3 }}>
          <Typography sx={{ fontSize: "14px" }}>
            Follow Reisty on social media to stay updated! Get thrilling news,
            access to special offers, and a preview of the delectable
            experiences you can look forward to by following us.
          </Typography>

          <Box sx={{ mt: 4 }}>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 700,
                borderBottom: "1px solid #d7d7d7",
                pb:1
              }}
            >
              Contact Information
            </Typography>

            <Box sx={{ mt: 3 }}>
              <ul>
                <li>
                  <Typography sx={{mt:2, fontSize:'16px'}}>
                    <b>Email:</b>{" "}
                    <a
                      href="mailto:support@reisty.com"
                      style={{ textDecoration: "underline" }}
                    >
                      {" "}
                      support@reisty.com
                    </a>
                  </Typography>
                </li>
                <li>
                  <Typography sx={{mt:2, fontSize:'16px'}}>
                    <b>Twitter:</b>{" "}
                    <a
                      href="https://twitter.com/reistyapp"
                      style={{ textDecoration: "underline" }}
                    >
                      {" "}
                      @reistyapp
                    </a>
                  </Typography>
                </li>
                <li>
                  <Typography sx={{mt:2, fontSize:'16px'}}>
                    <b>Facebook:</b>{" "}
                    <a
                      href="https://www.facebook.com/profile.php?id=61551987707359"
                      style={{ textDecoration: "underline" }}
                    >
                      Reisty
                    </a>
                  </Typography>
                </li>
                <li>
                  <Typography sx={{mt:2, fontSize:'16px'}}>
                    <b>Instagram:</b>{" "}
                    <a
                      href="https://www.instagram.com/reistyapp/"
                      style={{ textDecoration: "underline" }}
                    >
                      {" "}
                      @reistyapp
                    </a>
                  </Typography>
                </li>
                <li>
                  <Typography sx={{mt:2, fontSize:'16px'}}>
                    <b>LinkedIn:</b>{" "}
                    <a
                      href="https://www.linkedin.com/company/reisty/"
                      style={{ textDecoration: "underline" }}
                    >
                      {" "}
                      Reisty
                    </a>
                  </Typography>
                </li>
              </ul>

              <Typography sx={{mt:3}}>Thank you for selecting Reisty. Together, we can turn your next meal into something extraordinary!</Typography>
              <Typography sx={{mt:3}}>Are you a restauranteur looking to supercharge your restaurant business? <a href="https://restaurant.reisty.com/get-started" target="_blank" style={{textDecoration:'underline'}}><b> Contact sales here</b>.</a></Typography>
            </Box>
          </Box>
        </Box>
  <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between', my:4}}>
    <Link to="/about">

    <Button startIcon={<MdOutlineChevronLeft />}>About</Button>
    </Link>
        <Link to="/help">

    <Button endIcon={<MdKeyboardArrowRight />}>Help and Feedback</Button>
        </Link>
  </Box>
      </Box>
      <Footer />
    </>
  );
};

export default Contact;
