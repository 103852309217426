import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Container,
  Button,
  Divider,
  Box,
  useTheme,
  Typography,
  ListItem,
  TextField,
  InputAdornment,
  Grid,
  Avatar,
  MenuItem,

  useMediaQuery
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../../../Assets/logo/logo.svg";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { BiCurrentLocation } from "react-icons/bi";
import { MdKeyboardArrowRight } from "react-icons/md";
import {useDispatch,  useSelector } from "react-redux";
import { FaChevronRight } from "react-icons/fa";
import fork from "../../../Assets/icons/fork.svg";
import profileIcon from "../../../Assets/icons/profile.svg";
import headset from "../../../Assets/icons/headphone.svg";
import logoutIcon from "../../../Assets/icons/logout.svg";
import { logoutUser } from "../../../store/userSlice";
import dp from '../../../Assets/icons/dp.svg';
import { RiSearch2Line } from "react-icons/ri";

const top = ["Bar", "BBQ", "Chicken", "Cocktails", "Burgers", "Dessert", "Pizza", "Patio", "Salad", "Wines"]

const Drawers = () => {
  const theme = useTheme();
  const router = useLocation();
  const navigate = useNavigate();
    const dispatch = useDispatch()

  const [openDrawer, setOpenDrawer] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [color, setColor] = useState(false);
  
  const textInputRef = useRef(null);

  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);



    const handleLogout = () =>{
    handleClose()
    dispatch(logoutUser())
    // navigate("/")
  }

  const [params, setParams] = useState("")

    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
      const userData = useSelector((state) => state.user?.user);

    const isMobile = useMediaQuery(theme.breakpoints.down("md"))

    const handleClose= () =>{
      setOpenDrawer(false)
    }

 useEffect(() => {
    if (openDrawer && textInputRef.current) {
      textInputRef.current.focus();
    }
  }, [openDrawer]);
  return (
    <>
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <List sx={{ width: "100vw", height: "100vh", overflowY: "hidden" }}>
          <Box sx={{ borderBottom: "1px solid #dadada" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "0 auto",
                width: "85%",
                height: "75px",
              }}
            >
              <Link to="/">
                <img src={Logo} alt="Logo" width={80} />
              </Link>
              <Box>
                <IconButton onClick={() => setOpenDrawer(false)}>
                  <CloseIcon sx={{ color: "#bc172f", fontSize: "25px" }} />
                </IconButton>
              </Box>
            </Box>
          </Box>

          <Box sx={{ flexGrow: 1 }} />
        {
        isAuthenticated ? (
          <>
             <Box sx={{ margin: "0 auto", width: "95%", mt: 5 }}>
                    <Link to="/profile">
          <MenuItem sx={{}} onClick={handleClose}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
   <Avatar src={dp}/>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      textTransform: "capitalize",
                      color: "#565660",
                    }}
                  >
                    Hi, {userData?.first_name}
                  </Typography>
                  <Typography sx={{ color: "#717171", fontSize: "10px" }}>
                    see profile
                  </Typography>
                </Box>
              </Box>
              <FaChevronRight style={{ color: "#717171", fontSize: "12px" }} />
            </Box>
          </MenuItem>
          </Link>
          <Box sx={{ mt: 2 }}>
            <Link to="/profile/reservation">
            <MenuItem
            onClick={handleClose}
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 2,
                px: 2,
              }}
            >
              <img src={fork} width={15} />
              <Typography
                sx={{ color: "#2b2b2b", fontWeight: 300, fontSize: "12px" }}
              >
                Reservations
              </Typography>
            </MenuItem>
            </Link>
                <Link to="/profile">
            <MenuItem
            onClick={handleClose}
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 2,
                px: 2,
              }}
            >
              <img src={profileIcon} width={15} />
              <Typography
                sx={{ color: "#2b2b2b", fontWeight: 300, fontSize: "12px" }}
              >
                Account
              </Typography>
              
            </MenuItem>
            </Link>
            <Link to="/help"> 
            <MenuItem
            onClick={handleClose}
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 2,
                px: 2,
              }}
            >
              <img src={headset} width={15} />
              <Typography
                sx={{ color: "#2b2b2b", fontWeight: 300, fontSize: "12px" }}
              >
                Help & Support
              </Typography>
            </MenuItem>
            </Link>
            <MenuItem
            onClick={handleLogout}
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 2,
                px: 2,
              }}
            >
              <img src={logoutIcon} width={15} />
              <Typography
                sx={{ color: "#2b2b2b", fontWeight: 300, fontSize: "12px" }}
              >
             Logout
              </Typography>
            </MenuItem>
          </Box>
             </Box>
          </>
        ):(
          <>
            <Box sx={{ margin: "0 auto", width: "85%", mt: 5 }}>
            <a
              href="https://www.reisty.com/privacy-policy"
              style={{ textDecoration: "none" }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: "#2b2b2b",
                  mb: 2,
                  fontSize: "14px",
                  fontWeight: 400,
                }}
              >
                About
              </Typography>
            </a>

            <a
              href="https://www.restaurant.reisty.com"
              style={{ textDecoration: "none" }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: "#2b2b2b",
                  mb: 2,
                  fontSize: "14px",
                  fontWeight: 400,
                }}
              >
                For Restaurant
              </Typography>
            </a>

            <Link to="/login">
              <Button
                sx={{
                  marginLeft: "auto",
                  fontSize: "13px",
                  borderRadius: "5px",
                  py: 1.2,
                  mr: 1,
                  mt: 3,
                  border: "1px solid #d7d7d7",
                  px: 5,
                  borderRadius: "8px",
                  color: "#333",
                }}
              >
                Login
              </Button>
            </Link>
            <Link to="/login">
              <Button
                variant="contained"
                sx={{
                  marginLeft: "auto",
                  fontSize: "13px",
                  borderRadius: "5px",
                  py: 1.2,
                  mr: 1,
                  mt: 3,
                  px: 5,
                  borderRadius: "8px",
                }}
              >
                Sign up
              </Button>
            </Link>
          </Box>
          </>
        )}
        </List>
      </Drawer>

      <Drawer  open={openSearch} onClose={() => setOpenSearch(false)} anchor="right">
        <List sx={{ width: "100vw", height: "100vh", overflowY: "hidden" }}>
          <Box sx={{}}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "0 auto",
                width: "90%",
                height: "45px",
              }}
            >
              <Box>
                <IconButton onClick={() => setOpenSearch(false)}>
                  <MdOutlineKeyboardBackspace />
                </IconButton>
              </Box>
            </Box>
          </Box>

          <Box sx={{ margin: "0 auto", width: "90%" }}>
            <Box
              sx={{
                mt: 3,
                display: "flex",
                alignItems: "center",
                columnGap: 2,
              }}
            >
              <TextField
                inputRef={textInputRef}
                fullWidth
                value={params}
                onChange={(e)=>setParams(e.target.value)}
                placeholder="Search restaurants, cuisine"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FiSearch />
                    </InputAdornment>
                  ),
                  style: {
                    height: "45px",
                    borderRadius: "10px",
                  },
                }}
              />
                        <Link to={`/restaurant/find?Text=${params}`}>
              <IconButton
                sx={{
                  border: "1px solid #d7d7d7",
                  borderRadius: "10px",
                  height: "45px",
                  width: "45px",
                }}
              >
                <RiSearch2Line  style={{ color: "#BC172F" }} />
              </IconButton>
              </Link>
            </Box>

            <Box
              sx={{
                mt: 6,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography color="primary">All Restaurants in Lagos</Typography>
              <IconButton>
                <MdKeyboardArrowRight style={{ color: "#BC172F" }} />
              </IconButton>
            </Box>
            <Box sx={{ mt: 3 }}>
              <Typography>Top searches</Typography>
              <Box sx={{ mt: 1.5 }}>
                <Grid container spacing={2}>
                 {
                  top.map((item, index)=>(
                     <Grid item xs={3} key={index}>
                    <ListItemButton
                    onClick={()=>setParams(item)}
                    selected= {params === item}
                      sx={{
                        border: "0.6px solid #d7d7d7",
                        borderRadius: "8px",
                        display: "grid",
                        placeItems: "center",
                        "&.Mui-selected":{
border:'1px solid #bc172f'
                        }
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "12px", textAlign: "center"  , ...(params === item && {
                          color:'#bc172f'
                        })}}
                      >
                  {item}
                      </Typography>
                    </ListItemButton>
                  </Grid>
                  ))
                 }
                </Grid>
              </Box>
            </Box>
          </Box>
        </List>
      </Drawer>

      <Box>
        <IconButton sx={{ ml: 2 }} onClick={() => setOpenSearch(!openSearch)}>
          <FiSearch style={{ color: "#BC172F", fontSize: "20px" }} />
        </IconButton>
        <IconButton sx={{ ml: 2 }} onClick={() => setOpenDrawer(!openDrawer)}>
          {
            isAuthenticated ? (
   
          <Avatar src={dp} sx={{width:'35px', height:'35px'}}/>
                 
            ):(
    <MenuIcon
            sx={{ color: theme.palette.primary.main, fontSize: "20px" }}
          />
            )
          }
      
        </IconButton>
      </Box>
    </>
  );
};

export default Drawers;
