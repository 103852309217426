import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { MdArrowRightAlt } from "react-icons/md";
import RestaurantCard from "../Common/RestaurantCard";
import EventCard from "../Common/EventCard";
import { getAllEvents, getAllFeaturedRestaurants } from "../../../api";
import Loader from "../Common/Loader";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="hide_scrollbar" sx={{ pt: 5 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Featured = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [eventLoading, setEventLoading] = useState(false);
  const [data, setData] = useState(null);
  const [events, setEvents] = useState(null);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleGetFeatured = async () => {
    setIsLoading(true);
    await getAllFeaturedRestaurants()
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status) {
          setData(res?.data?.result);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleGetAllEvents = async () => {
    setEventLoading(true);
    await getAllEvents()
      .then((res) => {
        setEventLoading(false);
        if (res?.data?.status) {
          setEvents(res?.data?.result);
        }
      })
      .catch((err) => {
        setEventLoading(false);
      });
  };

  console.log(events, "Events");

  useEffect(() => {
    handleGetFeatured();
    handleGetAllEvents();
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Box
        sx={{
          width: { xl: "75%", lg: "85%", sm: "90%", xs: "90%" },
          margin: "0 auto",
          mt: 4,
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="fullWidth"
            >
              <Tab label="Dinnings" {...a11yProps(0)} />
              <Tab label="Experiences & Events" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: {
                      lg: "16px",
                      md: "16px",
                      sm: "14px",
                      xs: "12px",
                    },
                    fontWeight: 500,
                  }}
                >
                  Featured restaurants
                </Typography>
                <Typography
                  sx={{
                    color: "#2b2b2b",
                    fontSize: {
                      lg: "14px",
                      md: "14px",
                      sm: "12px",
                      xs: "10px",
                    },
                  }}
                >
                  Discover the best restaurants in your local area
                </Typography>
              </Box>
              <Box>
                <Link to="/restaurants/featured">
                  <Button
                    endIcon={<MdArrowRightAlt />}
                    sx={{
                      fontSize: {
                        lg: "14px",
                        md: "14px",
                        sm: "12px",
                        xs: "10px",
                      },
                    }}
                  >
                    See More{" "}
                  </Button>
                </Link>
              </Box>
            </Box>

            <Box sx={{ mt: 3 }}>
              {isLoading ? (
                <Grid container spacing={3}>
                  {[1, 2, 3, 4]?.map((restaurant, index) => (
                    <Grid item lg={3} md={3} sm={4} xs={6} key={index}>
                      <RestaurantCard loading />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Grid container spacing={isMobile ? 1 : 3}>
                  {data
                    ?.slice(0, isMobile ? 4 : 8)
                    ?.map((restaurant, index) => (
                      <Grid item lg={3} md={3} sm={6} xs={6} key={index}>
                        <Link to={`/restaurant/${restaurant?.RestaurantName}`}>
                          <RestaurantCard restaurant={restaurant} />
                        </Link>
                      </Grid>
                    ))}
                </Grid>
              )}
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: {
                      lg: "16px",
                      md: "16px",
                      sm: "14px",
                      xs: "12px",
                    },
                    fontWeight: 500,
                  }}
                >
                  Latest Events
                </Typography>
                <Typography
                  sx={{
                    color: "#2b2b2b",
                    fontSize: {
                      lg: "14px",
                      md: "14px",
                      sm: "12px",
                      xs: "10px",
                    },
                  }}
                >
                  Discover the best events and experiences in your local area
                </Typography>
              </Box>
              <Box>
                <Link to="/restaurants/featured">
                  <Button
                    endIcon={<MdArrowRightAlt />}
                    sx={{
                      fontSize: {
                        lg: "14px",
                        md: "14px",
                        sm: "12px",
                        xs: "10px",
                      },
                    }}
                  >
                    See More{" "}
                  </Button>
                </Link>
              </Box>
            </Box>

            <Box sx={{ mt: 3 }}>
              {eventLoading ? (
                <Grid container spacing={2}>
                  {[1, 2, 3, 4]?.map((restaurant, index) => (
                    <Grid item lg={3} md={3} sm={4} xs={6} key={index}>
                      <RestaurantCard loading />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Grid container spacing={isMobile ? 1 : 3}>
                  {events?.slice(0, isMobile ? 4 : 8)?.map((event, index) => (
                    <Grid item lg={4} md={4} sm={6} xs={6} key={index}>
                      <a
                        href={`https://ticket.reisty.com/event/${event?.EventName}/${event?.RestaurantId}?source=web`}
                      >
                        <EventCard event={event} />
                      </a>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Box>
          </CustomTabPanel>
        </Box>
      </Box>
    </>
  );
};

export default Featured;
